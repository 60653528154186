.page-select {
    padding-top: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: #282C34;
    font-size: 1.5em;
    font-weight: 600;

    .page-option {
      border-bottom: 5px solid transparent;
      padding: 10px 20px;
      cursor: pointer;

      &.active {
        color: #61dafb;
        border-bottom: 5px solid #61dafb;
        -webkit-transition: 1s all ease;
        transition: 1s all ease;
      }
    }
  }

.guide {
  padding-top: 60px;

	.guide-container {
		font-weight: 500;
    // width: 90%;
    // max-width: 1440px;
    margin: 0 auto;
    display: flex;
    color: #282C34;

    .content-container {
      // flex-grow: 3;
      background-color: white;
      width: 70%;
      margin: 0px 15px;
      h1 {
        font-weight: 600;
        font-size: 4em;
        margin: 20px 0;
      }

      h2 {
        margin: 25px 0 5px 0;
        font-weight: 600;
        font-size: 2em;
      }
    }
    .outline-container {
      background-color: #eee;
      // flex-grow: 1;
      width: 30%;
    }

	}
}