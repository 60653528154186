@import './utils';

.home {
  background-color: #20232a;
  font-family: 'Roboto', sans-serif;
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 49px);

  &.home-blog {
    background-color: lighten($theme-black, 10%);
  }

  .home-container {
    flex: 1 0 auto;
    font-weight: 500;
    width: 85%;
    max-width: 1440px;
    margin: 0 auto;
    // background-color: #20232a;
    // overflow: hidden;

    .select-product {
      font-size: 2rem;
      @media #{$sm-and-down} {
        font-size: 1.5rem;
      }
    }

    .home-slick {
      padding-top: 100px;
      padding-bottom: 50px;
      @media #{$sm-and-down} {
        padding-top: 50px
      }
      .slick-title {
        text-align: center;
        font-size: 2rem;
        color: $theme-blue;
        padding-bottom: 10px;
        &.blue {
          color: $theme-blue;
        }
        @media #{$sm-and-down} {
          font-size: 1.5rem;
        }
      }

      .product-slide {
        width: 200px;
        height: auto;
      }
    }

    .img-container {
      display: flex;
      align-items: center;
      justify-content: center;
      @media #{$sm-and-down} {
        // width: 300px;
        max-width: 100%;
      }
      // width: 600px;
      // max-width: 100%;
      // height: auto;
      margin: 50px auto 0px auto;
    }

    .about {
      max-width: 800px;
      margin: 0 auto;
      color: white;
      font-size: 1.2rem;
      padding-top: 2rem;
    }

  	.large-title {
      font-weight: 600;
      padding: 50px 0 0 0;
      text-align: center;
  	  font-size: 2rem;
      line-height: 3.8rem;
      color: white;
      @media #{$sm-and-down} {
        font-size: 1.5rem;
        padding-top: 25px;
      }

      &.blue {
        color: $theme-blue;
      }
    }

    .tagline {
      // padding-top: 5px;
      text-align: center;
      font-size: 1.2em;
      padding-top: 40px;
      @media #{$sm-and-down} {
        font-size: 1.1rem;
      }
      color: white;
    }

    .pp-symbols {
      // margin-bottom: 25px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      color: white;
      font-size: 3rem;
      max-width: 1000px;
      margin: 0px auto;
      padding-top: 100px;
      @media #{$sm-and-down} {
        padding-top: 50px
      }

      .process {
        text-align: center;
        font-size: 2rem;
        color: $theme-blue;
        &.blue {
          color: $theme-blue;
        }
        @media #{$sm-and-down} {
          font-size: 1.5rem;
        }
      }

      .symbols-container{
        display: flex;
        flex-direction: row;
        @media #{$sm-and-down} {
          flex-direction: column;
        }
        .symbol {
          margin: 15px 15px;
          font-size: 4rem;
          font-weight: 500;
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          @media #{$sm-and-down} {
            margin-top: 20px;
          }
          .stat-block {
            // line-height: 1.3rem;
            font-size: 1.1rem;
            .stat-label {
              margin-right: 32px;
              font-weight: 500;
              line-height: 1.5rem;
              font-size: 1.5rem;
              margin-bottom: 10px;
              color: $theme-orange;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: flex-start;
              @media #{$sm-and-down} {
                margin-bottom: 5px;
              }
              .icon-wrapper {
                color: white;
                // width: 34px!important;
                // height: 34px!important;
                margin-right: 10px;
                img {
                  margin-top: -4px;
                  height: 28px;

                }
              }

              @media #{$sm-and-down} {
                line-height: 1.4rem;
                font-size: 1.4rem;
                display: flex;
                justify-content: center;
                align-items: center;
                .icon-wrapper {
                  display: block;
                  margin-right: 10px;
                }
              }
            }
            // margin-left: 20px;
            // flex-grow: 1;
            // color: #fff;
            // font-size: 12px;
          }
        }
      }
    }

    .search-container {
      margin: 30px 0 0 0;
      display: flex;
      align-items: center;
      justify-content: center;
      .search-input {
        height: 1em;
        width: 50%;
        min-width: 300px;
        font-size: 24px;
        padding: 10px;
      }

      .search-button {
        margin-left: 10px;
        // padding-top: 20px;
        height: 2em;
        width: 2em;
        fill: white;
      }
    }
  }
}
