@import './utils';

.product-picker-footer {
  flex-shrink: 0;
  width: 100%;
  border-top: 1px solid $theme-blue;
  margin-top: 20px;
  // text-align: center;
  .pp-tips {
    max-width: 850px;
    margin: 0 auto;
    // padding: 0 15px;
    margin-bottom: 40px;
    @media #{$sm-and-down} {
      padding: 0 10px;
    }
    .tips {
      margin-left: 15px;
      font-size: 1.2rem;
      line-height: 1.4rem;
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      @media #{$sm-and-down} {
        font-size: .9rem;
      }
    }
    h1 {
      text-align: center;
      font-size: 1.6rem;
      font-weight: 600;
      margin: 0 0 20px 0;
      @media #{$sm-and-down} {
        font-size: 1.2rem;
      }
      // font-size: 1.6rem;
      // font-weight: 400;
      // margin-top: 30px;
      // margin-bottom: 20px;
    }
  }
}

.product-picker {
  // border-right: 1px solid ;
  // padding-right: 20px;
  margin: 0 auto;
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh);

  padding-right: 15px;

  @media (min-width: 1200px) {
    padding-right: 20px;
  }

  @media #{$sm-and-down} {
    padding-right: 0px;
    padding-top: 90px;
    &.padding-top-50 {
      padding-top: 70px;
    }
  }



  // background-color: $theme-blue;



  .product-picker-content {
    flex: 1 0 auto;
    max-width: 850px;

    @media #{$sm-and-down} {
      padding-bottom: 100px;
    }

    &.no-max-width {
      max-width: none;
    }

    .no-exact {
      font-size: 1.2rem;
      font-weight: 500;
      padding: 0 20px;
    }

    .multiple-select {
      text-align: center;
      font-weight: 700;
      color: $theme-orange;
      font-size: .9rem;
      margin-bottom: -10px;
      @media #{$md-and-up} {
        margin-bottom: -30px;
      }
    }

    .pp-symbols {
      // margin-bottom: 25px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 440px;
      margin: 0 auto 10px auto;
      @media #{$md-and-up} {
        min-width: 440px;
      }

      @media #{$sm-and-down} {
        flex-direction: column;
      }

      .symbol {
        margin: 15px 30px;
        font-size: 2rem;
        font-weight: 500;
        display: flex;
        flex-direction: row;
        align-items: center;

        .icon-wrapper {
          color: $theme-blue;
          // width: 34px!important;
          // height: 34px!important;
          margin-right: 10px;
        }
        .stat-block {
          line-height: 1.4rem;
          font-size: 1.3rem;
          .stat-label {
            font-weight: 400;
            line-height: 1.1rem;
            font-size: 1rem;
          }
          // margin-left: 20px;
          // flex-grow: 1;
          // color: #fff;
          // font-size: 12px;
        }
      }

      // display: flex;
      // justify-content: space-around;
      // align-items: center;
      // flex-direction: row;

    }

    .pp-tips {
      padding: 0 15px;
      .tips {
        margin-left: 15px;
        font-size: 1.2rem;
        line-height: 1.4rem;
        display: flex;
        align-items: center;
        margin-bottom: 15px;
      }
      h1 {
        font-size: 1.6rem;
        font-weight: 400;
        margin-top: 30px;
        margin-bottom: 20px;
      }
    }

    .intro-title {
      font-size: 28px;
      font-weight: 500;
      text-align: center;
      margin-bottom: 40px;
      margin-top: 40px;

      &.thank-you {
        font-size: 36px;
        margin-bottom: 10px;
        font-weight: 500;
        margin-top: 20px;
      }

      @media #{$sm-and-down} {
        margin-bottom: 20px;
        margin-top: 20px;
      }

    }

    .question-title {
      margin-bottom: 25px;
      font-size: 1.8rem;
      font-weight: 500;
      text-align: center;
      // margin-top: 40px;

      @media #{$sm-and-down} {
        padding: 0 10px;
        font-size: 1.4rem;
        margin-bottom: 20px;
      }

      strong {
        font-weight: 800;
      }

      img {
        margin: 0 auto;
      }

      &.white-letters {
        color: white;
        font-weight: 600;
      }

      &.thank-you {
        margin-top: 0;
        margin-bottom: 40px;
        font-size: 24px;
      }

      .noWrap{
        white-space: nowrap;
        display: inline-flex;
        flex-wrap: wrap;
      }
      .q-title svg{
        @media #{$md-and-up} {
          display: none;
        }
        display: inline-block;
        margin-top: .2rem;
        height: 1.5rem;
        width: 1.5rem;
        // margin-top: .5rem;
        margin-left: .25rem;
        color: rgb(50, 50, 50);
        transition: transform .75s;
        box-shadow: 3px 3px 6px rgba(black, 0.35);
        color: $theme-orange;
        border-radius: 12px;
        &.pulsef {
          animation: .4s jump ease infinite alternate;

          &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            border-radius: 300%;
            animation: shockwave 1s .65s ease-out infinite;
          }

          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            border-radius: 300%;
            animation: shockwave 1s .5s ease-out infinite;
          }
        }


      }
      .remove {
        transform: translateY(-3rem) scale(0);
      }
      .hideIcon {
        opacity: 0;
        transform: translate(3rem) scale(1);
      }
    }

    .roi-logo {
      display: block;
      max-width: 350px;
      margin: 0 auto;
      @media #{$sm-and-down} {
        max-width: 250px;
      }
    }

    .question-icon {
      display: block;
      height: 86px;
      margin: 0 auto;
    }

    .next-container {
      display: flex;
      align-items: center;
      justify-content: space-around;
      max-width: 350px;
      margin: 0 auto;
      margin-bottom: 35px;
      @media #{$sm-and-down} {
        margin-bottom: 0px;
      }
      .continue-btn {
        // margin: 0 auto;
        // margin-bottom: 60px;
        display: block;
        max-width: 200px;
        text-transform: uppercase;
        // font-weight: 450;
        cursor: pointer;
        @media #{$sm-and-down} {
          margin-bottom: 40px;
        }
        &.long {
          max-width: 100%;
        }
        &.results {
          background-color: $theme-orange !important
        }
      }
    }

    .calc-icon-container {
      margin-top: 10px;
      text-align: center;
    }

    .calc-options-container {
      display: flex;
      align-items: stretch;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin: 30px auto 30px auto;

      @media screen and (max-width: 1289px) {
        max-width: 630px;
      }

      @media screen and (max-width: 1024px) {
        max-width: 420px !important;
      }
      @media screen and (max-width: 814px) {
        max-width: 320px !important;
      }

      &.calc-options-2 {
        max-width: 420px;
        @media screen and (max-width: 814px) {
          max-width: 320px !important;
        }
      }

      &.calc-options-3 {
        max-width: 630px;
        @media screen and (max-width: 814px) {
          max-width: 320px !important;
        }
      }

      &.calc-options-1 {
        max-width: 210px;
        @media screen and (max-width: 814px) {
          max-width: 160px !important;
        }
      }

      @media #{$sm-and-down} {
        margin: 10px auto 10px auto;
        // flex-direction: column;
        // align-items: center;
      }



      .option-container {
        background-color: #f0c106;
        border-radius: 3px;
        // margin: 5px;
        &.recommended {
          // .yellow-bg {
          //     background-color: #f0c106;
          // }

          background-color: #f0c106;
          border-radius: 3px;
          // &:after {
          //   font-size: .9rem;
          //   text-align: center;
          //   padding: 3px 5px;
          //   display: block;
          //   color: black;
          //   font-weight: 600;
          //   content: "Popular Pick";

          //   background-color: $theme-blue;
          // }
          &.will-add {
            .calc-options {
                background-color: lighten($theme-blue, 40%);
            }
          }
        }
        // .lazy-load-image-background {
        //   margin-bottom: -6px;
        //   max-width: 100% !important;
        //   height: auto !important;
        //   // display: inline !important;
        //   background-color: white;
        // }
        img {
          // height: auto;
          background-color: white;
          // max-width: 200px;
        }

        .tool-tip-container {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #f0c106;
          border-radius: 3px;
          font-size: .9rem;
          padding: 3px 0px;
          color: black;
          font-weight: 600;

          @media screen and (max-width: 814px) {
            font-size: .9rem;
            line-height: .9rem;
            // font-weight: 300;
            // flex-direction: column;
            // align-items: center;
          }
        }
        &.recommended-with-tooltip {
          background-color: #f0c106;
          border-radius: 3px;
          // .tool-tip-container {
          //   width: 100%;
          //   display: flex;
          //   align-items: center;
          //   justify-content: center;
          //   background-color: #f0c106;
          //   border-radius: 3px;
          //   font-size: .9rem;
          //   padding: 3px 0px;
          //   color: black;
          //   font-weight: 600;
          // }
          &.will-add {
            .calc-options {
                background-color: lighten($theme-blue, 40%);
            }
          }
        }
          // background-color: red !important;

        .calc-options {
          // align-items: center;
          // display: flex;
          display: block;
          text-align: center;
          // text-transform: capitalize;
          // justify-content: center;
          padding: 12px 0 0 0;
          width: 200px;
          border-radius: 3px;
          border: 1px #777 solid;
          font-weight: 550;
          // color: #777;
          cursor: pointer;
          background-color: white;


          @media screen and (max-width: 814px) {
            width: 150px;
            font-size: .9rem;
            line-height: .9rem;
            padding-top: 16px;
            &.small-font {
              font-size: .75rem;
            }
            // font-weight: 400;
            // letter-spacing: -.5px;
          }

          &.with-hover{
            @media (hover: hover) {
              &:hover {
                background-color: lighten($theme-blue, 40%);
              }
            }
            // text-transform: uppercase;
//             &:hover {
//             @media (hover: hover) and (pointer: fine) {
//               background-color: lighten($theme-blue, 40%);
//               // color: white;
// }
//             }
          }
          &.selected {
            background-color: $theme-blue !important;
            color: white;
            .cost-symbols {
              color: white;
            }
          }

          &.disabled-option {
            pointer-events: none;
            background-color: $light-grey;
          }

          .option-text{
            margin-bottom: -4px;
            @media #{$sm-and-down} {
              margin-bottom: 0px;
            }
            // padding-right: 12px;
          }

          .cost-symbols {
            color: $theme-green;
            // color: #06f087;
            display: inline-block;
            svg {
              display: inline;
              margin-right: -2px;
            }
          }

          .option-count{
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 14px;
            line-height: .9rem;
            font-size: .9rem;
            margin-right: 2px;
            margin-bottom: 2px;
            .tool-tip-container-remaining {
              display: none;
            }
            &.remaining-with-tooltip {
              .tool-tip-container-remaining {
                display: flex;
              }
            }

            @media #{$sm-and-down} {
              font-size: .7rem;
              line-height: .7rem;
              padding-top: 4px;
              height: 16px;
              margin-bottom: 1px;
            }
          }
        }
      }
    }

    // .comparison-container {
    //   display: flex;
    //   flex-direction: column;
    //   border-radius: 10px;
    //   border-left: 1px solid $theme-blue;
    //   border-right: 1px solid $theme-orange;
    //   // box-shadow: 20px 0px 20px 10px $theme-orange,  -20px 0px 20px 10px $theme-blue;
    //   // box-shadow: -10px 0px 20px 10px #f08706;
    //
    //   .pick-type-container {
    //     display: flex;
    //     align-items: stretch;
    //     .pick-type {
    //       width: 50%;
    //       border-top-right-radius: 10px;
    //       border-top-left-radius: 10px;
    //       display: flex;
    //       align-items: center;
    //       line-height: 20px;
    //       padding: 10px 0;
    //       font-weight: 800;
    //       text-align: center;
    //       display: block;
    //       color: white;
    //       &.highend-pick {
    //         background-color: $theme-orange;
    //       }
    //       &.value-pick {
    //         background-color: $theme-blue;
    //       }
    //     }
    //   }
    //
    //   .spec-container {
    //     display: block;
    //     // text-align: center;
    //     margin: 0 10px;
    //     border-bottom: 1px solid $theme-grey;
    //
    //     .label {
    //       // padding: 0px 20px;
    //       // display: inline-block;
    //       // margin: 0 auto;
    //       // text-align: center;
    //       font-size: .9rem;
    //       // border-bottom: 1px solid $theme-grey;
    //     }
    //
    //     .value-container {
    //       display: flex;
    //       align-items: stretch;
    //       // justify-content: center;
    //       .value {
    //         text-align: center;
    //         width: 50%;
    //         padding: 0 5px 5px 5px;
    //         font-weight: 700;
    //         font-size: .9rem;
    //         // &:last-child { border-left: 2px solid $theme-orange; }
    //         // &:first-child { border-right: 2px solid $theme-blue; }
    //         &:first-child { padding-right: 10px; padding-left: 0px; }
    //         &:last-child { padding-right: 10px; padding-right: 0px; }
    //       }
    //     }
    //   }
    // }

  }

.option-count {
  	opacity: 1;
  	animation-name: fadeInOpacity;
  	animation-iteration-count: 1;
  	animation-timing-function: ease-in;
  	animation-duration: .5s;
  }

  @keyframes fadeInOpacity {
  	0% {
  		opacity: 0;
  	}
  	100% {
  		opacity: 1;
  	}
  }

  .roi-calc-footer {
    flex-shrink: 0;
    width: 100%;
    // border-top: lighten($theme-gold, 20%) 10px solid;
    text-align: center;

    // .roi-savings {
    //   padding: 20px;
    //   font-size: 28px;
    //   font-weight: 800;
    //   color: lighten($theme-blue, 10%);
    // }
    //
    // .roi-savings-text {
    //   font-size: 16px;
    //   font-weight: 800;
    //   padding-bottom: 20px;
    // }
  }

  .roi-results-footer {
    flex-shrink: 0;
    width: 100%;
    margin-top: 20px;
    text-align: center;

    .money-back {
      padding: 40px;
      font-weight: 500;
      // background-color: $theme-light-grey;

      .money-back-wrapper {
        // max-width: $app-max-width !important;
        width: 85%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        text-align: left;
        @media #{$sm-and-down} {
          flex-direction: column;
        }

        .money-back-icon {
          max-width: 20%;
          margin-right: 25px;
          @media #{$sm-and-down} {
            margin-bottom: 10px;
            max-width: 50%;
            margin-right: 0;
          }
        }
      }
    }

    .speak-to-rep {
      // border-top: lighten($theme-gold, 20%) 10px solid;
      font-size: 16px;
      font-weight: 800;
      padding-top: 20px;
    }

    .speak-to-rep-btn {
      margin-top: 20px;
      margin-bottom: 20px;
      text-transform: uppercase;
      font-weight: 500;
    }
  }

  .gold-border {
    margin: 20px 0;
    // border-top: lighten($theme-gold, 20%) 10px solid;
    // @media #{$md-and-up} {
    //   margin: 20px 15% 0 15%;
    // }
    margin-top: 20px;
  }

  .savings-line {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    font-weight: 800;
    align-items: center;
    @media #{$sm-and-down} {
      font-size: 16px;
      font-weight: 700;
    }
    @media #{$md-and-up} {
      margin: 20px 15% 0 15%;
    }

    &.total-savings {
      font-size: 24px;
    }


    .savings-description {
      flex-grow: 2;
    }
    .savings-amount {
      text-align: right;
      color: lighten($theme-blue, 10%);
      padding-left: 5px;
    }
  }
}

.progess-container-calc {
  // width: 100%;
  max-width: 350px;
  margin: 0 auto;
  // @media #{$sm-and-down} {
    // margin-bottom: 25px;

    ul{
      li {
        // height: 40px;
        width: 35px;
        // &:before {
        //   height:24px;
        //   width:24px;
        //   padding: 0;
        //   line-height: 28px;
        //   height: 30px;
        //   width: 30px;
        // }
      }
    }
    @media #{$sm-and-down} {
      margin-bottom: 25px;
    }
  // }
  // max-width: 600px;
}

.progressbar-calc {
  height: 60px;
  @media #{$sm-and-down} {
    height: auto;
  }
  counter-reset: step;
  padding-left: 0;
  margin: 0 auto;
  // text-align: center;
  display: flex;


  li {
    list-style-type: none;
    width: 30px;
    float: left;
    // padding-top:10px;
    font-size: .7rem;
    position: relative;
    text-align: center;
    color: black;
    // font-weight: 300;
    font-weight: 600;

    &::before {
      cursor: pointer;
      width: 20px;
      height: 20px;
      content: counter(step);
      counter-increment: step;
      // line-height: 18px;
      border: 2px solid #d4d4d4;
      display: block;
      text-align: center;
      margin: auto;
      // margin: 0 auto 10px auto;
      border-radius: 50%;
      background-color: white;
      color: #ddd;
      z-index: 1 !important;
      position: relative;
      font-weight: 600;
    }

    &::after {
      width: 100%;
      height: 2px;
      content: '';
      position: absolute;
      background-color: #d4d4d4;
      top: 9px;
      left: -50%;
      // z-index: -1;
    }

    &:first-child::after {
      transition: all 1s;
      content: none;
    }

    &.active {
      transition: all 1s;
      // color: green;
    }

    &.active::before {
      transition: all 1s;
      border-color: lighten($theme-blue, 10%);
      background-color: lighten($theme-blue, 10%);
      color: white;
      font-weight: 600;
    }

    &.active-current::before {
      transition: all 1s;
      border-color: lighten($theme-blue, 10%);
      background-color: white;
      color: lighten($theme-blue, 10%);
      font-weight: 600;
      // color: lighten($theme-color, 10%);
    }

    &.active, &.active-current {
      &::after {
        transition: all 1s;
        // color: lighten($theme-color, 10%);
      // background-color: lighten($theme-color, 10%);
      // color: white;
      // color: lighten($theme-color, 10%);
      }
    }
    &.active {
      &::after {
        transition: all 1s;
        background-color: lighten($theme-blue, 10%);
      }
    }
  }
}

%cta-button {
  font-weight: 700;
  border: 0;
  background-color: $theme-blue;
  border-radius: 5px;
  color: white;
  display: inline-block;
  box-sizing: content-box;
  margin: 10px;
  min-width: 50px;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none !important;
  box-shadow: 2px 2px 4px rgba(black, 0.3);
  transition: all $transition-speed;

  &:hover {
    transition: all $transition-speed;
    // color: white;
    box-shadow: 4px 4px 8px rgba(black, 0.3);
  }
}

.action-btn-gld {
  @extend %cta-button;

  backface-visibility: hidden;
  background-color: $theme-orange;
  // color: white;
  // font-size: $theme-font-normal;

  &:disabled {
    background-color: #ccc;
    &:hover {
      transform: none;
    }
  }

  &:hover {
    // transform: perspective(1px) translateZ(0);
    // backface-visibility: hidden;
    cursor: pointer;
    transform: scale(1.05);
  }

  &.small {
    padding: 5px 10px;
  }

  &.xs {
    margin-top: 6px;
    margin-bottom: 6px;
  }

  &.xs {
    padding: 2px 6px;
    font-weight: 600;
  }

  &.grey {
    background-color: $theme-grey;
  }

  &.light-grey {
    background-color: $light-grey;
    color: black;
  }

  &.blue {
    background-color: $theme-blue;
  }

  &.m-13 {
    margin-top: 0px;
    margin-bottom: 6px;
  }

  &.see-details {
    font-weight: 500;
  }

  &.no-min {
    min-width: 30px;
  }
}

.results-product {
  border-radius: 5px;


  &:focus {
    outline: none;
    background-color: rgb(242, 253, 255);
    .row-value {
      background-color: rgb(242, 253, 255) !important;

      &.alternate-color {
        background-color: rgb(227, 250, 255) !important;
      }
    }
  }

  // .result-details-row {
  // font-size: .8rem;
  // padding: 5px 15px;
  // display: flex;
  // background: white;
  // align-items: center;
  // background-color: #eee;
  // border: 1px solid #eee;

  // }
}

.sub-text {
  max-width: 850px;
  margin: 0 auto;
  padding-bottom: 100px;
  @media #{$sm-and-down} {
    // padding: 0 20px;
    margin-top: 15px;
    padding-bottom: 100px;
  }

  table {
    font-size: .8rem;
    min-width: 50%;
    // width: 100%;
    .first-column {
      // background-color: $theme-blue;
      color: black;
      text-align: right;
      padding-right: 10px;
      font-weight: bold;
      border-right: 5px solid black;
      @media #{$sm-and-down} {
        color: white !important;
        border-right: 5px solid white;
      }

    }
    .first-row {
      // background-color: $theme-blue;
      font-weight: bold;
      color: black;
      text-align: center;


      .top-row {
        border-bottom: 5px solid black;
        @media #{$sm-and-down} {
          color: white !important;
          border-bottom: 5px solid white;
        }
      }
    }


    .poor {
      background-color: #ff4d4d;
      // color: red;
      font-weight: 600;
    }
    .ok {
      background-color: yellow;
      font-weight: 600;
    }
    .good {
      background-color: #a6ff4d;
      font-weight: 600;
    }
    .great {
      background-color: #00ff00;
      font-weight: 600;
    }

    .empty{
      background-color: white;
      @media #{$sm-and-down} {
        background-color: transparent !important;
      }
      border-bottom: none;
    }

    td {
      @media #{$sm-and-down} {
        color: black !important;
      }
      text-align: center;
      padding: 10px;
    }
  }

  h1 {
    font-size: 1.6rem;
    line-height: 1.6rem;
    font-weight: 500;
    margin: 0 0 20px 0;
  }
  .section {
    line-height: 1.4rem;
    margin: 15px 0;
    strong {
      @media #{$sm-and-down} {
        color: $theme-blue;
      }
      font-weight: 800;
    }
    img {
      margin: 0 auto;
      max-width: 100%;
    }

    .section-flex {
      padding-top: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      @media #{$sm-and-down} {
        flex-direction: column;
      }
      .section-flex-item {
        min-width: 200px;
        img {
          padding-left: 20px;
          max-width: 200px;
          @media #{$sm-and-down} {
            padding-left: 0;
            max-width: 100%;
            padding-bottom: 20px;
            padding-top: 5px;
          }
        }
        // padding: 0 10px;
      }
    }
  }
}

.desktop-top-picks {
  @media #{$sm-and-down} {
    display: none;
  }
}

.mobile-top-picks {
  padding-top: 10px;
  @media #{$md-and-up} {
    display: none;
  }
}

.top-pick-text {
  text-align: center;
  font-size: 2rem;
  line-height: 2rem;
  // padding-bottom: 5px;
  // margin-bottom: 54px;
  font-weight: 500;
  &.no-margin {
    margin-bottom: 0px;
  }

  @media #{$sm-and-down} {
    margin-bottom: 10px;
    font-size: 1.6rem;
    line-height: 1.6rem;
  }
}

.your-filters {
  padding-top: 40px;
  text-align: center;
  font-size: 2rem;
  line-height: 2rem;
  // padding-bottom: 5px;
  margin-bottom: 40px;
  font-weight: 500;

  &.no-margin {
    padding-top: 0px;
  }

  @media #{$sm-and-down} {
    margin-bottom: 10px;
    font-size: 1.6rem;
    line-height: 1.6rem;
  }

}

.more-results {
  margin-top: 10px;
  // width: 100%;
  // width: 350px;
  // max-width: 350px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
    // &.high-end {
    //     &:before {
    //       font-size: .8rem;
    //       text-align: center;
    //       margin: 2.5px 5px;
    //       display: block;
    //       color: black;
    //       font-weight: 600;
    //       content: "High-end Pick";
    //       background-color: #f0c106;
    //       // background-color: $theme-blue;
    //     }
    //   }
    // }
    .top-picks-wrapper {
      // box-shadow: 0px 0px 20px 10px $theme-blue;
      margin: 20px;
      border-radius: 10px;
      max-width: 350px;
      border-bottom: 1px solid $theme-blue;
      border-right: 1px solid $theme-blue;
      border-left: 1px solid $theme-blue;

      @media #{$sm-and-down} {
        margin: 5px;
      }

      .bottom-flip-through {
        padding: 0 10px 10px 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 30px;
        // text-align: center;
        // font-weight: 700;
        // font-size: .9rem;
        svg {
          color: $theme-blue;
          cursor: pointer;
          height: 25px;
          width: 25px;
          margin: 5px;

        }
      }

      .pick-label {
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        line-height: 20px;
        // padding: 7.5px 10px;
        font-weight: 600;
        height: 40px;
        padding: 0px 5px;
        // vertical-align: -50%;
        // font-size: .8rem;
        text-align: center;
        // margin: 2.5px 5px;
        color: white;
        background-color: $theme-blue;

        svg {
          height: 25px;
          width: 25px;
          margin: 5px;
        }
      }
      &.high-end {
        // box-shadow: 0px 0px 20px 10px $theme-orange;
        border-bottom: 1px solid $theme-orange;
        border-right: 1px solid $theme-orange;
        border-left: 1px solid $theme-orange;
        .pick-label {
          background-color: $theme-orange;
        }
        // .result-container {
        //   .product-top-container {
        //     .product-name {
        //       .price {
        //         color: $theme-orange;
        //       }
        //     }
        //   }
        //   .feat-btn-container {
        //     display: flex;
        //     align-items: center;
        //     justify-content: flex-end;
        //     padding: 3px 10px;
        //     margin-bottom: 5px;
        //     .price-drop-btn {
        //       color: #04a55d;
        //       padding: 1px 3px;
        //       margin-right: 10px;
        //     }
        //     i {
        //       height: 18px;
        //       weight: 18px
        //     }
        //   }
        // }
        .bottom-flip-through {
          svg {
            color: $theme-orange !important;
          }
        }
      }
  }
  .result-container {
    // margin-bottom: 10px;
    border-radius: 10px;
    // padding-bottom: 10px;
    box-shadow: none;
    border: 1px solid #a4a4a4;
    &.not-top {
      margin: 20px;
    }
    &.top-picks {
      border: none;
      // border: 1px solid #a4a4a4;
      border-radius: 10px;
    }

    .actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 3px 10px;
      flex-direction: row-reverse;
      &.engine {
        justify-content: space-around;
      }

      &.multiple-link-btns {
        display: block;
        // flex-direction: column-reverse;
        // .price-drop-btn {
        //
        // }
      }

      .price-drop-btn {
        color: #04a55d;
        padding: 1px 3px;
        // margin-right: 10px;
        transition: all $transition-speed;
        &:hover {
          cursor: pointer;
          transform: scale(1.05);
        }
      }

      .multiple-see-details {
        // margin-top: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        .sd-text {
          text-align: left;
          font-weight: 600;
          padding: 0 10px;
        }
        .sd-buttons {
          display: flex;
          // max-width: 200px;
          flex-wrap: wrap;
          button {
            margin: 5px !important;
          }
        }
      }
    }
  }
}


.result-container {
  cursor: default;
  // width: 100%;
  // display: flex;
  // // flex-wrap: wrap;
  // // justify-content: space-around;
  // // border-radius: 10px;
  // // margin: 0 auto;
  // // box-shadow: 0px 0px 20px 10px $theme-blue;
  // width: 400px;
  // margin-bottom: 30px;
  // padding-bottom: 10px;
  // box-shadow: none !important;
  // border: 1px solid #a4a4a4;

// .best-result-container > * {
//   margin: 20px;
// }


  .btn-m10 {
    margin: 10px auto !important;
  }

  .feat-btn-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 3px 10px;

    &.multiple {
      &:last-child {
        background-color: red;
      }
      flex-direction: column-reverse;
    }

    .price-drop-btn {
      color: #04a55d;
      padding: 1px 3px;
      // margin-right: 10px;
      transition: all $transition-speed;
      &:hover {
        cursor: pointer;
        transform: scale(1.05);
      }
    }

    i {
      height: 18px;
      // weight: 18px
    }

    .links-container{
      display: flex;
      flex-direction: row;
      .policy-link{
        color: #264a62;
        font-size: .85rem;
        transition: all $transition-speed;
        padding: 5px 25px;
        &:hover{
          cursor: pointer;
          transform: scale(1.05);
        }
      }
      svg{
        display: inline;
        color: $theme-green;
      }
    }
  }

  .product-top-container {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: flex-start;
    padding: 10px 10px 0px 10px;
    transition: all $transition-speed;
    &.engine {
      align-items: center;
    }
    @media #{$sm-and-down} {
      min-height: 115px;
    }
    &:hover {
      transform: scale(1.05);
    }

    .product-img {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 135px;
      width: 135px;
      white-space: nowrap;
      @media #{$sm-and-down} {
        height: 100px;
        width: 100px;
        // min-width: 100px;
      }
      // margin: 10px auto;
      img {
        max-width: 135px;
        max-height: 135px;
        // max-height: 200px;
        // height: 200px;
        // height: auto;
        border-radius: 10px;

        @media #{$sm-and-down} {
          max-height: 100px;
          max-width: 100px;
        }
      }
    }

    .name {
      text-transform:capitalize;
      max-width: 200px;
      // width: 100%;
      // height: 20px;
      text-align: left;
      font-weight: 700;
      font-size: 1.0rem;
      padding: 0px 0px 0px 20px;
      @media #{$md-and-up} {
        font-size: 1.1rem;
        line-height: 1.2rem;
      }
      // line-height: 1.0rem;
    }
    .by-brand {
      max-width: 200px;
      // width: 100%;
      // height: 20px;
      color: $theme-grey;
      text-align: left;
      // font-weight: 700;
      font-size: .8rem;
      padding: 0px 0px 5px 20px;

      .brand {
        // text-transform:capitalize;
        // width: 100%;
        // height: 20px;
        color: black;
        font-weight: 700;
        font-size: .9rem;
      }
    }
    .price {
      // color: $theme-blue;
      font-weight: 500;
      display: inline-block;
      border-radius: 5px;
      font-size: 1.0rem;
      margin: 5px 10px 0px 20px;
      padding: 0px 3px;
      background-color: #04a55d;
      color: white;
      @media #{$md-and-up} {
        font-size: 1.2rem;
      }

      &.sale {
        margin-left: 20px;
        background-color: #F03806;
      }
    }
    .original-price{
      font-weight: 500;
      display: inline-block;
      border-radius: 5px;
      font-size: 1.0rem;
      margin: 5px 10px 0px 20px;
      padding: 0px 3px;
      color: white;
      @media #{$md-and-up} {
        font-size: 1.2rem;
      }
    }
    .strike{
    background-color: #04a55d;
    background-image: gradient(linear, 19.1% -7.9%, 81% 107.9%, color-stop(0, transparent), color-stop(.48, transparent), color-stop(.5, #000), color-stop(.52, transparent), color-stop(1, transparent));
    background-image: repeating-linear-gradient(163deg, transparent 0%, transparent 48%, #fff 50%, transparent 52%, transparent 100%);
    }
  }

  .product-details {
    display: flex;
    justify-content: center;


    .specs {
      // text-transform:capitalize;
      text-align: right;
      width: 400px;
      display: flex;



      // width: 20%;
      // padding-top: 205px;
      .label {
        text-align: right;
        height: 20px;
        border-right: 1px solid #aaa;
        width: 200px;
        // text-align: right;
        // font-weight: 600;
        // margin-right: 10px;
        padding: 2.5px 5px;
        // line-height: 1.0rem;
        background-color: #fcfcfc;
        font-size: .9rem;
        &.alternate-color {
          background-color: #f6f6f6;
        }
      }
      .value {
        text-transform:capitalize;
        width: 200px;
        // width: 100%;
        // height: 20px;
        text-align: left;
        font-weight: 700;
        font-size: 1.0rem;
        padding: 2.5px 5px;
        // line-height: 1.0rem;
        background-color: white;
        background-color: #fcfcfc;
        &.alternate-color {
          background-color: #f6f6f6;
        }
        display: flex;
        align-items: center;
        // background-color: #eee;
      }
    }
    .slider-container {
      display: block;
      // width: 400px;
      // max-width: 80%;
      .engine-description {
        padding: 10px;
      }

      .flex-row {
        width: 348px;
        @media #{$sm-and-down} {
          width: 325px;
          // width: calc(100% - 40px);
        }
        &.additional_colors {
          justify-content: center;
          width: auto;
          max-width: 400px;
          @media #{$sm-and-down} {
            width: auto;
            max-width: 325px;
            // width: calc(100% - 40px);
          }
        }
        display: flex;
        align-items: stretch;
        background-color: #fcfcfc;
        &.alternate-color {
          background-color: #f6f6f6;
        }

        &.additional-specs {
          // background-color: #333;
          color: black;
          justify-content: center;
          border-bottom: 1px solid black;
          border-top: 1px solid black;
        }

        .label {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          text-align: end;
          line-height: 1rem;
          // height: 20px;
          // border-right: 1px solid #aaa;
           width: 124px;
          // text-align: right;
          // font-weight: 600;
          // margin-right: 10px;
          padding: 2.5px 5px;
          // line-height: 1.0rem;
          // background-color: #fcfcfc;
          font-size: .9rem;

          @media #{$sm-and-down} {
            padding: 2.5px 5px;
          }
        }
        .value {
          display: flex;
          align-items: center;
          border-left: 1px solid #aaa;
          text-transform:capitalize;
          width: 224px;
          // width: 100%;
          // height: 20px;
          text-align: left;
          font-weight: 700;
          font-size: .9rem;
          padding: 2.5px 5px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          &.additional-colors {
            white-space: normal;
            overflow: visible;
            flex-wrap: wrap;
            background-color: white;
            border-left: none;
            width: auto;
            max-width: 100%;

            span {
              margin: 10px 5px 0px 5px;
              &:hover {
                cursor: pointer;
                transform: scale(1.2);
              }
            }
          }

          // .additional-images{
          //   height: 56px;
          //   width: 56px;
          //   margin: 10px 5px 0px 5px;
          //   &:hover {
          //     cursor: pointer;
          //     transform: scale(1.2);
          //   }
          // }

          svg {
            color: green;
            height: 21px;
            width: 21px;
            &.red-false {
              color: red;
            }
          }
          // @media #{$sm-and-down} {
          //   padding: 3px 5px;
          // }
          &:hover {
            overflow: visible;
            white-space: normal;
            height:auto;  /* just added this line */
          }
          // line-height: 1.0rem;
          // background-color: white;
          // background-color: #fcfcfc;
          // background-color: #eee;
          &.review{
            text-decoration: underline;
            color: hsl(206,100%,40%);
            font-weight: 500;
            &:hover{
              cursor: pointer;
            }
            span{
              padding-left: .25rem;
            }
          }
          .star-container {
            display: inline-flex;
            align-items: center;
            position: relative;
            .star{
              width: 18px;
              display: flex;
              color: #e8c641;

              &:last-of-type {
                margin-right: 0;
              }
            }
            .star-overlay {
              background-color: black;
              mix-blend-mode: color;
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              z-index: 1;
            }
          }
        }
      }
    }
  }
}

.results-container {
  margin-bottom: 100px;
  display: flex;
  justify-content: space-between;

  .specs {
    width: 20%;
    padding-top: 205px;
    .row-label {
      height: 20px;
      // text-align: right;
      font-weight: 600;
      // margin-right: 10px;
      padding: 5px 5px;
      // line-height: 1.0rem;
      background-color: #fcfcfc;
      font-size: 1.0rem;
      &.alternate-color {
        background-color: #f6f6f6;
      }
    }
  }

  .slider-container {
    display: block;
    max-width: 80%;

    .row-value {
      width: 100%;
      height: 20px;
      text-align: left;
      font-weight: 400;
      font-size: 1.0rem;
      padding: 5px 0px;
      // line-height: 1.0rem;
      background-color: white;
      background-color: #fcfcfc;
      &.alternate-color {
        background-color: #f6f6f6;
      }
      // background-color: #eee;
    }
  }


}


.selection-container {
  &.desktop {
    // margin-right: -20px;
    .selection-values {
      max-width: 300px;
    }
    @media #{$sm-and-down} {
      display: none !important;
    }
  }
  position: sticky;
  // margin-left: 15px;
  top: 0px;
  max-height: calc(100vh);
  min-width: 320px;
  max-width: 320px;
  @media #{$sm-and-down} {
    min-width: auto;
    max-width: none;
  }
  // padding-left: 15px;
  border-left: 1px black solid;
  padding-top: 60px;
  overflow-y: scroll;

  &.sidebar {
    padding: 0 15px;
    @media #{$sm-and-down} {
      padding-bottom: 500px !important;
    }
    border-left: none;
    min-width: auto;
    background-color: &$theme-grey !important;
    color: white;
    &.constant {
      strong {
        background-color: $theme-orange !important;
      }
    }
  }

  .selection-row {
    display: flex;
    align-items: center;
    border-top: 1px solid #aaa;
    padding-left: 5px;
    &:hover {

      background-color: lighten($theme-blue, 40%);
      @media #{$sm-and-down} {
        background-color: #949494;
      }
    }
    .lock-button-container {
      width: 40px;

      // height: 100%;

    }


    .selection-row-flex {
      &:hover {
        background-color: lighten($theme-blue, 40%);
        @media #{$sm-and-down} {
          background-color: #949494;
          .filter{
            .selection-labels {
              color: black;
            }
          }
        }
      }
      width: calc(100% - 5px);
      text-transform:capitalize;
      // border-top: 1px solid #aaa;
      // border-bottom: 1px solid black;
      display: flex;
      align-items: center;
      // border-bottom-left-radius: 5px;
      // border-bottom-right-radius: 5px;
      padding: 5px;

      // @media #{$md-and-up} {
      //   border-top: 1px solid #aaa;
      // }

      .selection-values {
        font-weight: 500;
        font-size: .9rem;
      }

      .selection-numbers {
        display: inline-block;
        font-weight: 600;
        color: white;
        background-color: $theme-blue;
        height: 20px;
        min-width: 20px;
        margin-right: 5px;
        font-size: .8rem;
        border-radius: 10px;
        line-height: 20px;
        text-align: center;
      }

      .filter {
        padding-left: 5px;
        .selection-labels {
          color: #555;
          @media #{$sm-and-down} {
            color: #ccc;
          }
          font-size: .8rem;
          // margin-right: 5px;
          font-weight: 400;
          white-space: nowrap;
          margin-bottom: -3px;
        }
      }

      // strong {
      //   padding: 2.5px;
      // }

      &.constant {
        .selection-values {
          display: inline-block;
          padding: 0px 2px;
          border-radius: 5px;
          background-color: lighten($theme-orange, 30%);
          @media #{$sm-and-down} {
            background-color: lighten($theme-orange, 10%);
          }
        }
      }
    }
    // margin-bottom: 5px;

  }
  .legend {

    &.desktop {
      @media #{$sm-and-down} {
        display: none;
      }
    }
    margin-top: 20px;
    padding-bottom: 20px;
    .color-key {
      margin-right: 5px;
      display: inline-block;
      border-radius: 7.5px;
      height: 15px;
      width: 15px;
      background-color: lighten($theme-orange, 30%);
      @media #{$sm-and-down} {
        background-color: lighten($theme-orange, 10%);
      }
    }
  }
}

.selection-container::-webkit-scrollbar {
  /* This is the magic bit for WebKit */
  display: none;
}

.show-more {
  padding: 20px 0 0 0;
  margin-bottom: 20px;
  font-size: 1.2rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  svg {
    margin-right: 10px;
  }

  &.show-tips {
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 0px;
    h1 {
      margin-top: 0px;
    }
  }
}

.rotate {
    animation: spin 2s linear infinite;
}

.pp-tooltips {
  max-width: 250px;
  opacity: 1 !important;
  div {
    font-size: .9rem;
    font-weight: 500;
    line-height: 19.5px;
    text-align: left;
  }
}


.fa-spinner {
  animation: spin-animation 1s infinite;
  display: inline-block;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

.lazy-wrapper {
  // -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    // -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    // box-sizing: border-box;         /* Opera/IE 8+ */
  margin: 0 auto;
  // // width: 100%;
  // // width: 600px;
  // width: 100%;
  max-width: 850px;
  // padding-bottom: 17.0%;
  // display: block;
  // display: inline-block;
//   .post .lazy-container {
//  padding-bottom: 17.0%;
// }
  // height: calc(100% - 6px);
  .lazy-load-image-background {
    // margin-bottom: -6px;
    position: relative;
    // height: auto !important;
    // aspect-ration: 5.87/1;
    max-width: 100% !important;
    // padding-bottom: 17.035%;
    // height: 0px !important;
    // margin-bottom: -6px;
    display: block !important;
    // max-width: 100% !important;
    // // min-width: 100%;
    margin: 0 auto;
    // // display: block !important;
    // height: auto !important;
    img {
      position: absolute;
      top: 0px;
      left: 0px;
      max-width: 100% !important;
      // width: fit-content;
      // height: auto !important;
      // aspect-ration: 5.87/1;
      // padding-bottom: 17.0%;
      // position: relative;
      // max-width: 100% !important;
      // margin: 0 auto;
      // max-width: 100% !important;
      // min-width: 100%;
      // display: block !important;
      // height: auto !important;
    }
  }
}

.spinner-container {
  min-height: calc(100vh);
  padding-top: 60px;
  margin: 0 auto;
  &.not-full {
    min-height: auto;
  }
}

.Toastify__toast--default {
  color: black !important;
}

.image-center {
  display: block !important;
  margin: 10px auto 5px auto !important;
  img {
    border-radius: 3px;
  }
}

.Toastify__toast--info {
  @media #{$sm-and-down} {
    max-width: 90%;
    margin: 10px auto;
  }
  border-radius: 5px;
  background-color: $theme-grey !important;
  font-size: 1rem;
  font-weight: 500;

  .click-here {
    padding: 10px 0;
    text-decoration: underline;
  }
}

.lock-btn {
  color: $theme-orange !important;
  width: 16px !important;
  height: 16px !important;
}

.mobile-only {
  display: none;
  @media #{$xs-only} {
    display: block;
  }
}

.toast-product-name {
  text-transform:capitalize;
}

.lock-button {
  transition: all $transition-speed;
  margin: 0 auto;
  font-size: 1.3rem;
  // padding: 0 5px;
  opacity: 90%;
  @media #{$sm-and-down} {
    opacity: 40%;
  }
  color: $light-grey;
  &:hover {
    cursor: pointer;
    transform: scale(1.2);
  }
  &.locked {
    color: $theme-orange;
    opacity: 100%;
  }

  &.lock-inline {
    margin: auto;
    display: inline-block !important;
    transform: none;
    cursor: auto;
    // padding: 0px;
    // margin-right: 5px;

  }
}
//Prevent scroll chaining of descriptions
//padding accounts for the 25% y translate of "side-bar btm-nav"
//doesn't affect any popup that doesn't have its own scroll bar.
.selection-container{
  &.sidebar{
    overscroll-behavior-y: contain;
    padding-bottom: 25vh;
  }
}

.beta-tag {
  background-color: $theme-blue;
  color: white;
  padding: 2px 5px;
  border-radius: 5px;
  font-size: 1.0rem;
  font-weight: 500;
  margin-right: 5px;
}

.related-products-black {
  .related-products {
    color: black !important;
  }

  border-top: 1px solid black;
  background-color: #e4e4e4;
  &.starter {
    padding-top: 20px;
    background-color: white;
  }
}

.vote-container {
  text-align: center;
  .vote-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      text-align: center;
      svg {
        height: 25px;
        width: 25px;
        margin: 0 auto;
      }
    }
  }
}
