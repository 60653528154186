@import './utils';

.saved-results-header {
  text-align: center;
  font-size: 2.5rem;
  line-height: 2.5rem;
  padding-bottom: 5px;
  margin-bottom: 35px;
  font-weight: 500;
  color: white;
  padding-top: 70px;
  &.no-margin {
    margin-bottom: 0px;
  }

  @media #{$sm-and-down} {
    margin-bottom: 10px;
    font-size: 1.6rem;
    line-height: 1.6rem;
    padding-top: 20px;
  }
}

.saved-results-container {
  margin: 85px auto 100px auto;
  width: 100%;
  max-width: 1200px;

  .no-results {
    color: white;
  }

  .saved-results-flex {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    // width: 100%;
    // max-width: 1200px;
    .saved-result-container {
      background-color: white;
      padding: 10px;
      margin-bottom: 10px;
      text-align: center;
      width: 31%;
      margin-right: 2%;
      border-radius: 5px;
      border: 1px $theme-grey solid;

      .top-row-flex {
        display:flex;
        justify-content:center;
        .close-container {
          width: 24px;
          height: 24px;
          padding: 5px;
          border-radius: 11px;
          &:hover {
            background-color: $light-grey;
          }
          // margin: 0 auto;
          svg {
            margin: 0;
            // float: right;
          }
        }
        .result-title {
          flex:1;
          transform: translateX(-24px);
   // display: flex;
   // justify-content: center;
          // margin: 0 10px 10px 10px;
          margin: 0 24px;
          // margin-top: -16px;
          font-weight: 600;
          font-size: 1.2rem;
        }
      }

      .result-goto {
        border-radius: 5px;
        transition: all $transition-speed;
        &:hover {
          transform: scale(1.05);
        }
        transition: all $transition-speed;
      }

      @media #{$sm-and-down} {
        width: 100%;
        margin-right: 0px;
        // margin-bottom: 20px;
        // margin-top: 20px;
      }


      .result-date {
        color: $theme-grey;
        font-weight: 500;
        font-size: .8rem;
      }

      .result-btns {

        display: flex;
        justify-content: center;

      }

      .product-img {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding: 10px 0;
        img {
          max-height: 200px;
          height: 200px;
          border-radius: 10px;
          margin: 0px auto;
        }
      }
      .preview-row {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fcfcfc;
        &.alternate-color {
          background-color: #f6f6f6;
        }
        .label {
          text-align: right;
          // height: 20px;
          // border-right: 1px solid #aaa;
           width: 200px;
          // text-align: right;
          // font-weight: 600;
          // margin-right: 10px;
          padding: 5px 5px;
          // line-height: 1.0rem;
          background-color: #fcfcfc;
          font-size: .9rem;
          &.alternate-color {
            background-color: #f6f6f6;
          }
        }
        .value {
          border-left: 1px solid #aaa;
          text-transform:capitalize;
          width: 200px;
          // width: 100%;
          // height: 20px;
          text-align: left;
          // font-weight: 700;
          font-size: 1.0rem;
          padding: 5px 5px;
          // line-height: 1.0rem;
          background-color: white;
          background-color: #fcfcfc;
          &.alternate-color {
            background-color: #f6f6f6;
          }
          // background-color: #eee;
        }
      }
    }
  }
}

.filterSelect {
    display: inline-block;
    width: 280px;
    padding: 0px;
    .css-yk16xz-control {
      border-radius: 2px;
    }
    &.add-contributor-options {
      position: relative;
      // overflow: auto;
      width: 100% !important;
      // position: fixed !important;
      // .css-26l3qy-menu {
      //   width: auto;
      //   position: fixed;
      //   z-index: auto;
      // }
    }
  }
