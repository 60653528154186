
.slick-slider {
  margin: 0 auto;
  width: 500px;
  @media #{$sm-and-down} {
    max-width: calc(100vw - 74px)
  }

  .slick-track {
    display: flex !important;
  }

  .slick-title {
    font-size: 1.5rem;
    padding-bottom: 10px;
  }
  .slick-list {
    // margin: 0 -20px;
  }
  .slick-slide {
    height: inherit !important;
    display: flex !important;
    margin-left: 2px;
    margin-right: 2px;
    background: black;
    border-radius: 20px;
    padding: 20px;
    font-size: 1.1rem;
    color: white;
    // margin: 0 10px;
    max-width: 496px;
    @media #{$sm-and-down} {
      max-width: calc(100vw - 78px)
      // max-width: 280px;
    }

    div {
      display: flex !important;
    }



    .slide-content {
      // height: inherit !important;
        display: flex !important;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
    }
    .slider-bottom {
      color: $theme-orange;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 0.9rem;
      margin-top: 10px;
    }
  }
  .slick-dots {
    // margin-top: 15px;
    font-family: 'slick';
    font-size: 15px;
    line-height: 15px;
    position: relative;

    .active-slick {
    }
    li {

      @media #{$sm-and-down} {
        margin: 0;
        // max-width: 280px;
      }
      height: 30px;
      width: 30px;
      button {
        height: 30px;
        width: 30px;
        &:before {
          font-size: 15px;
          line-height: 15px;
          color: $theme-orange !important;
        }
      }
    }
  }
  &.product-slider {
    // max-height: 150px;
    width: 350px;
    max-width: 100%;
    height: 95px;
    .slick-slide {
      border-radius: 0px;
      padding: 0px;
      background-color: white;
      cursor: pointer;
    }
    &.center {
      .slick-slide {
        height: 88px;
        display: flex !important;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }
}
