@import './utils';

html {
    line-height: 1.5;
}

.disclaimer {
  // background-color: #f0c106;
  color: $theme-blue;
  // color: white;
  font-weight: 500;
  padding: 5px;
  border-radius: 5px;
  font-size: .9rem;
  margin-bottom: 20px;
}

.nav-bar {
  background-color: black;
  position: fixed;
  color: white;
  z-index: 1000;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  top: 0;
  left: 0;
  width: 100%;

  .nav-bar-container {
    font-weight: 500;
    display: flex;
    // height: 30px;
    // padding: 15px;
    font-size: 30px;
    line-height: 30px;
    align-items: center;
    justify-content: space-between;
    // width: 100%;
    color: $theme-blue;

    // .nav-logo {
    //   margin-left: 15px;
    //   height: 30px;
    // }
    .logo-img {
      div{
        display: block !important;
      }
      // max-height: 30px;
      // width: auto;
      // margin-left: 15px !important;
    }

    .flex-items {
      // margin: 15px;
      // width: 100%;
      display: flex;
      justify-content: flex-end;
      // color: red;
      text-align: right;

      &.right-nav-links {
        // font-size: 1rem;
        .nav-link {
          padding: 15px 10px;
          font-size: 1rem;
          @media #{$sm-and-down} {
            font-size: .9rem;
          }

          &:hover {
            background-color: #20232a;
          }
        }
      }
      .dropdown-padding {
        padding: 15px;
      }
      .dropdown-open {
        background-color: #20232a;
        color: darken($theme-blue, 20%)
        // color: $theme-blue;
      }
    }
  }
}

.simplybuy-container {

  .ai-rec-engine {
    // width: 100vw;
    width: 600px;
    max-width: calc(100vw - 30px);
    padding-left: 10px;
    padding-right: 10px;
    height: 200px;
    h1 {
      font-size: 1.5rem;
      text-align: center;
      // margin-bottom: 30px;
    }
    .beta-tag1 {

      display: block;
      margin: 0 auto;
      text-align: center;
      margin-bottom: 10px;
      span {
        display: inline;
        background-color: $theme-blue;
        color: white;
        padding: 2px 5px;
        border-radius: 5px;
        font-size: 1.0rem;
        font-weight: 500;
      }
    }
    span {
      display: block;
      width: 100%;
    }

    &.top-title {
      height: auto;
      padding-bottom: 10px;
    }
  }


  // padding-top: 60px;
  display: flex;
  justify-content: space-between;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  min-height: calc(100vh - 49px);
  // background-color: #20232a;
  @media (min-width: 768px) {
    // width: 750px;
  }
  @media (min-width: 992px) {
    // width: 970px;
  }
  @media (min-width: 1200px) {
    // width: calc(100vw - 40px);
    padding-right: 20px;
    padding-left: 20px;
    // width: 1170px;
  }

  &.no-padding-right {
    @media #{$md-and-up} {
      padding-right: 0px
    }
  }

  &.dark {
    background-color: $theme-black
  }
}

.contact-textarea {
  height: 200px;
}

.member-modal {
  // outline: none !important;
  position: fixed;
  border-radius: 10px;
  // width: 800px;
  width: 400px;
  z-index: 1040;
  top: 100px;
  @media #{$sm-and-down} {
    top: 2vh;
  }
  left: calc((100vw - 400px)/2);
  &.login {
    width: 400px;
    left: calc((100vw - 400px)/2);
    @media #{$sm-and-down} {
      left: calc((100vw - 300px)/2);
    }
  }
  margin: 0 auto;
  background-color: white;
  box-shadow: 0 5px 15px rgba(0,0,0,.5);
  :focus { outline: none; }

  @media #{$sm-and-down} {
    max-width: 300px;
    left: calc((100vw - 300px)/2);
  }

  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    height: 25px;
    width: 25px;
    cursor: pointer !important;
    z-index: 9;
  }


  .modal-content {
    padding: 20px;
    display: flex;
    .login-form {
      padding-right: 20px;
    }
    .register-form {
      // padding-right: 20px;
      // border-right: 1px #555 solid;
      @media #{$sm-and-down} {
        // padding-right: 0;
        // border-right: none;
      }
    }

    @media #{$sm-and-down} {
      flex-direction: column;
    }

    .quick-save {
      padding-left: 20px;

      .quick-link {
        @media #{$sm-and-down} {
          margin-bottom: 0;
        }
      }

      @media #{$sm-and-down} {
        padding-left: 0;
      }

      .copy-container {
        // display: flex;
        // align-items: space-between;
        // justify-content: center;
        input {
          height: 35px;
        }
        // button {
        //   margin: 0 0 0 15px;
        // }
      }
    }
  }

  h3 {
    color: black;
    margin: 0 0 15px;
    position: relative;
    text-align: center;
    font-size: 1.8rem;
  }
  .register-text {
    color: #555;
    margin-bottom: 15px;
    // text-align: center;
    font-weight: 500;
  }

  .form-field {
    border-radius: 3px;
    border: 1px solid #ced4da;
    background-color: #fff;
    font-size: 1rem;
    padding: .375rem .75rem;
    margin: 10px 0 0 0;
    width: 100%;
    box-sizing: border-box;
    &:focus {
      outline: none;
    }
  }

  &.remove {
    height: 120px;
  }
  &.add {
    height: 500px;
    &.admin-add {
      height: 600px;
    }
  }
  &.edit {
    height: 300px;
  }
}


.text-danger {
  color: #ff0000;
}

.btn-center-container {
  display: flex;
  align-items: center;
  justify-content: center;

  &.result-options {
    button {
      width: 38%;
      margin: 5px !important;
    }
    flex-wrap: wrap;
  }
  // &.btn-moblie-top {
  //   margin-top: -20px;
  //   margin-bottom: 10px;
  // }
}

@keyframes jump {
  0% {
    transform: scale(1);
    box-shadow: 0 1px 2px rgba(0,0,0,.15);
  }
  100% {
    transform: scale(1.05);
    box-shadow: 0 4px 20px rgba(0,0,0,.1);
  }
}

.switch-ctn-container {
  display: flex;
  align-items: center;
  justify-content: center;

  .pulse {
    animation: .4s jump ease infinite alternate;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      border-radius: 300%;
      animation: shockwave 1s .65s ease-out infinite;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      border-radius: 300%;
      animation: shockwave 1s .5s ease-out infinite;
    }
  }

  .switch-label {
    font-weight: 800;
    font-size: 1.1rem;
    margin-bottom: 5px;
    &.left {
      margin-right: 10px;
      color: $theme-blue;
      text-shadow: rgb(0, 0, 0) 3px 0px 0px, rgb(0, 0, 0) 2.83487px 0.981584px 0px, rgb(0, 0, 0) 2.35766px 1.85511px 0px, rgb(0, 0, 0) 1.62091px 2.52441px 0px, rgb(0, 0, 0) 0.705713px 2.91581px 0px, rgb(0, 0, 0) -0.287171px 2.98622px 0px, rgb(0, 0, 0) -1.24844px 2.72789px 0px, rgb(0, 0, 0) -2.07227px 2.16926px 0px, rgb(0, 0, 0) -2.66798px 1.37182px 0px, rgb(0, 0, 0) -2.96998px 0.42336px 0px, rgb(0, 0, 0) -2.94502px -0.571704px 0px, rgb(0, 0, 0) -2.59586px -1.50383px 0px, rgb(0, 0, 0) -1.96093px -2.27041px 0px, rgb(0, 0, 0) -1.11013px -2.78704px 0px, rgb(0, 0, 0) -0.137119px -2.99686px 0px, rgb(0, 0, 0) 0.850987px -2.87677px 0px, rgb(0, 0, 0) 1.74541px -2.43999px 0px, rgb(0, 0, 0) 2.44769px -1.73459px 0px, rgb(0, 0, 0) 2.88051px -0.838247px 0px;
      &.not-checked {
        // font-weight: 500;
        text-shadow: none;
        color: lighten($theme-blue, 15%);
        // color: lighten($theme-blue, 20%);
      }
    }
    &.right {
      margin-left: 10px;
      color: $theme-orange;
      text-shadow: rgb(0, 0, 0) 3px 0px 0px, rgb(0, 0, 0) 2.83487px 0.981584px 0px, rgb(0, 0, 0) 2.35766px 1.85511px 0px, rgb(0, 0, 0) 1.62091px 2.52441px 0px, rgb(0, 0, 0) 0.705713px 2.91581px 0px, rgb(0, 0, 0) -0.287171px 2.98622px 0px, rgb(0, 0, 0) -1.24844px 2.72789px 0px, rgb(0, 0, 0) -2.07227px 2.16926px 0px, rgb(0, 0, 0) -2.66798px 1.37182px 0px, rgb(0, 0, 0) -2.96998px 0.42336px 0px, rgb(0, 0, 0) -2.94502px -0.571704px 0px, rgb(0, 0, 0) -2.59586px -1.50383px 0px, rgb(0, 0, 0) -1.96093px -2.27041px 0px, rgb(0, 0, 0) -1.11013px -2.78704px 0px, rgb(0, 0, 0) -0.137119px -2.99686px 0px, rgb(0, 0, 0) 0.850987px -2.87677px 0px, rgb(0, 0, 0) 1.74541px -2.43999px 0px, rgb(0, 0, 0) 2.44769px -1.73459px 0px, rgb(0, 0, 0) 2.88051px -0.838247px 0px;
      &.not-checked {
        text-shadow: none;
        // font-weight: 500;
        color: lighten($theme-orange, 15%);
        // color: lighten($theme-orange, 20%);
      }
    }

  }
}

.pointer {
  cursor: pointer;
}

.dropdown-menu-container {
  display: none;
  &.show {
    display: flex;
  }
  background-color: $theme-black;
  min-width: 200px;
  position: absolute;
  z-index: 10;
  top: 60px;
  right: 0px;
  text-align: right;
  flex-direction: column;
  font-size: 1rem;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  div {
    cursor: pointer;
    padding: 20px;
    &:hover {
      background-color: $theme-blue;
      color: black;
    }
  }
}

.side-bar {
    position: fixed;
    z-index: 105;
    height: 100% !important;
    // display: flex;
    flex-direction: column;
    border-right: 1px solid;
    border-radius: 0;
    border-color: lighten($theme-black, 10%);
    background-color: lighten($theme-black, 10%);
    transition: 0.8s ease;
    top: 0px;

    @media #{$md-and-up} {
      display: none;
    }

    &.btm-nav {
      border-right-width: 0px;
      background-color: $theme-black;
      color: white;
      box-shadow: 0px 0px 21px #000000;
      border-top-right-radius: 10px;
      padding-bottom: 100px;
      border-top-left-radius: 10px;
    }

    .content {
      // height: 80%;
      overflow-y: scroll;
      padding-bottom: 30px;
    }
    // overflow-y: scroll;
    // overflow-x: hidden;
    .your-filters {
      padding-top: 15px;
      // margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .legend {
        font-size: 1rem;
        text-align: right;
      }
    }
}

.toggle-menu {
    padding: 10px 0px;
    padding-right: 2.5px;
    height: auto;
    font-size: 1.1rem;
    font-weight: 700;
    border-top-right-radius: 11px;
    border-bottom-right-radius: 11px;
    width: 30px;
    position: absolute;
    top: 250px;
    outline: none;
    z-index: 104;
    border-color: lighten($theme-black, 10%);
    background-color: lighten($theme-black, 10%);
    border-left: 0;
    writing-mode: vertical-rl;
    color: white;
    &:focus {
      outline: none;
    }
    cursor: pointer;
    @media #{$md-and-up} {
      display: none;
    }
}

.navTabs {
  @media #{$md-and-up} {
    display: none;
  }
  background-color: transparent;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 70px;
  // padding-bottom: 20px;
  bottom: 0px;
  width: 100%;
  color: white;
  z-index: 107;
  background-color: lighten($theme-black,10%);
  left: 0px;

  &.space-between {
    justify-content: space-between;
  }

  .toggle-menu2 {
    max-width: 33.33%;
    color: white;
    cursor: pointer;
    height: 65px;
    text-align: center;
    flex-grow: 1;
    border-width: 0px;
    background-size: 200% auto;
    background-color: lighten($theme-black,10%);

    transition: .5s;
    background-repeat: repeat-x;
    background-repeat: repeat-y;
    background-position: 0 0;
    font-weight: 700;
    font-size: .7rem;
    padding-top: 5px;
    flex-direction: column;
    svg {
      padding-bottom: 5px;
      font-size: 1.5rem;
    }

    .img {
      transition: all .5s ease ;
      height: 24px;
      width: 24px;
      background-image: url('/simplybuy-favicon-copy.png');
      background-size: contain;
    }
    .test {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.nav-selected {
      color: $theme-blue;
      svg {
        color: $theme-orange;
      }
      .img {
        transition: all .5s ease ;
        height: 24px;
        width: 24px;
        background-image: url('/simplybuy-favicon.png');
        background-size: contain;
      }
      // background-color: $theme-black;
      // background-position: 0 -40px;
    }
  }
}

.side-bar-backdrop {
    visibility: hidden;
    position: fixed;
    height: calc(100vh);
    width: calc(100vw);
    opacity: 0;
    background-color: #20232a;
    top: 0px;
    left: 0px;
    transition: visibility 0s, opacity .8s ease-in-out;
    -webkit-transition: visibility 0s, opacity .8s ease-in-out;
    &.open {
      z-index: 104;
      visibility: visible;
      opacity: .4;
    }
}

.orange-link {
  color: $theme-orange;
  text-decoration: underline;
  :link { color: #0000EE !important; }
  :visited { color: #551A8B !important; }
}

.register-link {
  color: #0000EE !important;
  color:blue;
  text-decoration:underline;
  &:hover {
    text-decoration: underline;
  }
}

.switch-container {
  display: flex;
  align-items: center;
}

.like-link {
  cursor:pointer !important;
  color:blue !important;
  text-decoration:underline !important;
}

.simplybuy-footer {
    flex-shrink: 0;
    width: 100%;
    // border-top: 1px solid #06bef0;
    // margin-top: 10px;
    background-color: black;
    color: white;
    font-size: .7rem;
    padding: 10px;

    &.extra-padding {
      @media #{$sm-and-down} {
        padding-bottom: 80px;
      }
    }

    .footer-container {
      max-width: 800px;
      margin: 0 auto;
      // padding: 10px 0 10px 0;
      font-size: .8rem;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-wrap: wrap;
      div {
        // text-align: center;
        // min-width: 120px;
        // width: 33%;
        // max-width: 200px;
        white-space: nowrap;
        padding: 5px;
        &:hover {
          cursor: pointer;
          color: #06bef0;
        }
      }
    }
}

.psg-container {
  &.home-page {
    padding-top: 50px;
  }

  .product-category{
    color: $theme-orange;
    font-size: 0.9rem;
    margin-bottom: -7.5px;
    // font-weight: 800 !important;
    // text-align: left;
  }


  color: $theme-blue !important;
  text-align: center;
  font-size: 1.2rem;

  .related-products {
    color: black;
    font-size: 1.4rem;
    font-weight: 500;
    &.white {
      color: white !important;
    }
    &.blue {
      color: $theme-blue !important;
      @media #{$md-and-up} {
        color: black !important;
      }
    }
  }

  .products-flex {
    font-size: 1rem;
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    justify-content: center;
    padding: 5px 0 25px;

    .product-container {
      margin: 5px;
      &.black-text {
        color: black;
      }
      a {
        text-decoration: none;
      }

      .product-option {
        display: block;
        text-align: center;
        padding: 10px;
        width: 200px;
        border-radius: 5px;
        // border: 1px $theme-blue solid;
        font-weight: 600;
        cursor: pointer;
        background-color: white;
        color: black;
        box-shadow: 2px 2px 4px rgba(black, 0.3);
        transition: all $transition-speed;

        &:hover {
          transition: all $transition-speed;
          // color: white;
          transform: scale(1.05);
          box-shadow: 4px 4px 8px rgba(black, 0.3);
        }

        &:hover {
          background-color: $theme-blue;
          color: white;
          a {color: white;}
        }
        a {
          text-decoration: none;
          color: black;
        }

        &.inverse {
          background-color: $theme-blue;
          color: white;
          margin-bottom: 10px;
        }
      }
    }

    &.max-size {
      max-width: 800px;
      margin: 0 auto;
    }
  }

  .picker-description {
    color: black;
    font-size: 1.2rem;
    font-weight: 500;
    max-width: 400px;
    margin: auto;
  }
}

.remove-inverse {
  .inverse {
    background-color: white !important;
    color: black !important;
    &:hover {
      background-color: $theme-blue;
      color: white;
      a {color: white;}
    }
  }
}

.title-toast {
  font-size: 1.2rem;
  font-weight: 600;
}

.display-small {
  @media #{$md-and-up} {
    display: none;
  }
}

.google-signin {
  border: 1px solid #ced4da;
  padding: 6px 12px;
  display: inline-block;
  width: 100%;
  border-radius: 3px;
  img {
    display: inline-block;
    height: 18px;
    width: 18px;
    margin-right: 10px;
  }
}
.or-seperator {
  width: 100%;
  height: 20px;
  border-bottom: 1px solid $theme-orange;
  text-align: center;
  height: .9rem;
  margin: 10px 0px;
  span {
    font-size: .8rem;
    padding: 0 10px;
    background-color: white;
    // color: #ced4da;
    // font-style:italic;
    // color: $theme-orange;
  }
}
.search-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  height: 34px;
  border-radius: 10px;
  max-width: 600px;
  margin: 0px auto;
  span {
    border-radius: 10px;
    margin: 0px !important;
  }
  input {
   outline:none;
  }
  .data-list {
    padding: 0px 10px;
    height: 34px;
    width: 100%;
    max-width: 600px;
    border-radius: 10px;
    margin-right: -70px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  }
}

.search-options-container {
  margin: 0px auto;
  margin-top: -10px;
  padding-top: 10px;
  background-color: darken(white, 10%);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 100%;
  max-width: 600px;
  overflow: hidden;
  .search-option {
    max-width: 600px;
    color: black;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 2px 5px;
    // border-bottom-right-radius: 5px;

    &:hover {
      cursor: pointer;
      background-color: lighten($theme-blue, 40%);
    }
  }
}

.beta-tag1 {
  display: block;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 10px;
  span {
    display: inline;
    background-color: $theme-blue;
    color: white;
    padding: 2px 5px;
    border-radius: 5px;
    font-size: 1.0rem;
    font-weight: 500;
  }
}

.standard-link {
  text-decoration: underline !important;
  color: #3B5998 !important;
  cursor: pointer !important;
}
.standard-link-no-underline {
  color: #3B5998 !important;
  cursor: pointer !important;
}

.buy-coffee {
  svg {
    display: inline-block;
    margin-bottom: 5px;
    text-decoration: underline !important;
    color: #3B5998 !important;
    cursor: pointer !important;
  }
}

// @keyframes background-change {
//   0% {
//     background-color: $theme-orange;
//     color: white;
//   }
//   100% {
//     color: black;
//     background-color: white;
//   }
// }

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


@keyframes bounce-in-right {
  0% {
    opacity: 0;
    // transform: translateY(100px);
      transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0); }
}

@media #{$sm-and-down} {
  @keyframes bounce-in-right {
    0% {
      opacity: 0;
      // transform: translateY(100px);
        transform: translateY(200px);
    }
    100% {
      opacity: 1;
      transform: translateY(0); }
  }
}


@keyframes bounce-in-left {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  60% {
    opacity: 1;
    transform: translateX(-0px);
  }
  80% {
    opacity: 1;
    transform: translateX(5px); }
  100% {
    opacity: 1;
    transform: translateX(0); }
}

.test1 {
  opacity: 0;
  animation: bounce-in-right .6s both;
  animation-delay: .2s;
}

.test2 {
  animation: bounce-in-right .6s both;
  animation-delay: .4s;
}
.test3 {
  animation: bounce-in-right .6s both;
  animation-delay: .6s;
}
.test4{
  animation: bounce-in-right .6s both;
  animation-delay: .8s;
}
.test5 {
  animation: bounce-in-right .6s both;
  animation-delay: 1s;
}
.test6 {
  animation: bounce-in-right .6s both;
  animation-delay: 1.2s;
}

.test13 {
  animation: bounce-in-right .6s both;
  animation-delay: 1.4s;
}
.test14 {
  animation: bounce-in-right .6s both;
  animation-delay: 1.6s;
}
.test15{
  animation: bounce-in-right .6s both;
  animation-delay: 1.8s;
}

.pch {
  animation: fade-in 1.5s both;
  animation-delay: 1s;
}

.pch2 {
  animation: fade-in 1.5s both;
  animation-delay: 1.8s;
}

.pagination-controls {
  display: flex;
  justify-content: center; /* Center the pagination controls */
  align-items: center;
  margin-top: 20px;
  font-family: Arial, sans-serif;
}

.pagination-controls button {
  background-color: #06bef0; /* Your theme-blue color */
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 0 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 16px;
}

.pagination-controls button:disabled {
  background-color: #d4d4d4; /* Disabled buttons will use light grey */
  cursor: not-allowed;
}

.pagination-controls button:not(:disabled):hover {
  background-color: #f08706; /* Your theme-orange for hover effect */
}

.pagination-controls span {
  margin: 0 10px;
  font-size: 18px;
  color: #555; /* Your theme-grey */
}
