.blog-index-title {
  color: $theme-blue;
  font-size: 2rem;
  @media #{$lg-and-up} {
    font-size: 3rem;
  }
  text-align: center;
  font-weight: 800;
  padding: 25px;
}

.blog-index {
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: 25px;
  @media #{$sm-and-down} {
    flex-direction: row;
  }
  // margin-top: 100px;
  .blog-card {
    margin: 5px;
    transition: all $transition-speed;
    width: 300px;
    border-radius: 10px;
    // background-color: white;
    background-color: lighten($theme-black, 10%);
    color: white;

    &:hover {
      cursor: pointer;
      transform: scale(1.05);
    }

    img {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      background-color: black;
      width: 300px;
    }
    .blog-title {
      padding: 15px;
      font-weight: 600;
    }
    .blog-created {
      padding: 0 15px 15px 15px;
      color: $theme-blue;
      font-weight: 600;
      font-size: .8rem;
    }
    .blog-topic {
      padding: 0px 15px 15px 15px;
      color: $theme-blue;
      font-weight: 600;
      font-size: .8rem;
    }

    .bottom-split {
      color: $theme-blue;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 0.9rem;
      margin-top: 10px;
    }
  }
}

.blog-title-block{
  padding-left: 7.5%;
  background-color: lighten($theme-black, 10%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media #{$sm-and-down} {
    flex-direction: column;
    padding: 0px;
  }
  &.faq {
    max-width: 800px;
    // max-width: 800px;
    margin: 0 auto;
    padding-left: 0
  }
  // margin-bottom: 15px;
  .title-left {
    min-width: 55%;
    padding: 15px 30px 15px 0px;
    color: white;
    font-size: 2rem;

    &.faq {
      padding-bottom: 15px;
      max-width: 800px;
      // max-width: 800px;
      margin: 0 auto;
      padding-left: 0;
    }
    @media #{$lg-and-up} {
      font-size: 2.25rem;
    }
    @media (min-width: 1200px) {
      font-size: 2.5rem;
    }
    @media #{$sm-and-down} {
      padding: 15px 15px 0px 15px;
    }
  }
  .image-right {
    display: inherit;
    img {
      // min-width: 400px;
      @media #{$sm-and-down} {
        min-width: auto;
      }
    }
  }
  .created-at {
    color: $theme-blue;
    @media #{$sm-and-down} {
      padding: 0px 15px 15px 15px;
    }
  }
}

.blog-container {
  flex: 1 0 auto;
  font-weight: 500;
  background-color: lighten($theme-black, 10%);
  // width: 85%;
  // max-width: 1440px;
  // margin: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media #{$sm-and-down} {
    flex-direction: column;
  }

  .blog-post {
    background-color: $theme-black;
    // width: 85%;
    width: 100%;
    // max-width: 800px;
    margin: 0 auto;
    color: white;
    padding-right: 30px;
    padding-left: 30px;
    padding-bottom: 100px !important;

    @media #{$sm-and-down} {
      // padding-bottom: 25px;
      padding-bottom: 100px !important;
    }

    @media #{$sm-and-down} {
      padding-left: 25px;
      padding-right: 25px;
    }
    .blog{
      padding-top: 25px;
      max-width: 800px;
      // max-width: 800px;
      margin: 0 auto;
      .section {

        a {
          color: $theme-orange;
          text-decoration: underline;
          :link { color: #0000EE !important; }
          :visited { color: #551A8B !important; }
        }

        .section-flex {
          display: flex;
          justify-content: space-around;
          // align-items: flex-end;
          &.end {
            align-items: flex-end;
            @media #{$sm-and-down} {
              align-items: center;
            }
            img {
              max-width: 160px;
            }
          }
          .honorable-mention {
            border-radius: 5px;
            margin-bottom: 20px;
            &:hover {
              cursor: pointer;
              transition: all $transition-speed;
              // color: white;
              transform: scale(1.05);
              box-shadow: 0px 0px 30px rgba($theme-orange, 0.3);
            }
            .title {
              color: $theme-orange;
              text-align: center;
              max-width: 160px;
            }
          }


          @media #{$sm-and-down} {
            flex-direction: column;
            width: 100%;
            align-items: center;
            justify-content: center;
          }
          .image {
            margin-right: 20px;
            display: flex;
            flex-direction: column;
            .action-btn-gld {
              margin: 5px 0px 0px 0px;
              @media #{$sm-and-down} {
                margin-bottom: 5px;
              }
            }
            a {
              color: white !important;
            }
            img {
              max-width: 150px;
              border-radius: 5px;
              &:hover {
                cursor: pointer;
                transform: scale(1.05);
              }
              @media #{$sm-and-down} {
                max-width: 150px;
              }
            }
          }
        }
        // text-indent: 20px;
        scroll-margin-top: 60px;
        padding: 15px 0px 0px 0px;
        // color: red;
        .section-header {
          // text-indent: 0px;
          scroll-margin-top: 60px;
          color: $theme-blue;
          padding: 15px 0px 15px 0px;
          font-weight: 600;
          font-size: 1.8rem;
          text-align: center;
        }
        .section-sub-header {
          // text-indent: 0px;
          scroll-margin-top: 60px;
          padding: 15px 0px 0px 0px;
          color: $theme-orange;
          font-weight: 500;
          font-size: 1.1rem;
        }
      }
    }
  }
  .blog-post-right {
    min-width: 25%;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 25px;
    margin: 0;
    position: -webkit-sticky;
    position: sticky;
    top: 60px;
    overflow: scroll;
    padding-bottom: 100px;
    min-height: calc(100vh - 60px);
    max-height: calc(100vh - 60px);
    background-color: lighten($theme-black, 10%);
    @media #{$sm-and-down} {
      width: 100%;
      padding-bottom: 25px;
      min-height: auto;
      display: none;
    }
    .link-index {
      @media #{$sm-and-down} {
        display: none;
      }
      .jump-to {
        // text-align: center;
        border-top: 1px black solid;
        color: white;
        font-size: 1.5rem;
        padding-top: 25px;
      }

      .header {
        scroll-margin-top: 2em;
        color: $theme-blue;
        padding-top: 10px;
        cursor: pointer;
      }
      .sub-header {
        scroll-margin-top: 2em;
        color: $theme-orange;
        padding-left: 25px;
        font-size: .8rem;
        cursor: pointer;
      }
    }
  }
}

.sidebar-jump-to {
  min-width: 25%;
  padding-left: 25px;
  padding-right: 10px;
  padding-top: 25px;
  // margin: 0;
  // position: -webkit-sticky;
  // position: sticky;
  // top: 60px;
  overflow: scroll;
  padding-bottom: 100px;
  @media #{$md-and-up} {
    background-color: lighten($theme-black, 10%);
  }


    .jump-to {
      // text-align: center;
      border-top: 1px black solid;
      color: white;
      font-size: 1.5rem;
      padding-top: 25px;
    }

    .header {
      scroll-margin-top: 2em;
      color: $theme-blue;
      padding-top: 15px;
      cursor: pointer;
      font-size: 1.2rem;
    }
    .sub-header {
      scroll-margin-top: 2em;
      color: $theme-orange;
      padding-top: 5px;
      padding-left: 25px;
      font-size: 1rem;
      cursor: pointer;
    }
}

.iframe {
  position: relative;
  left: -25px;
  background-color: white;
  width: 100vw;
  margin: 0px auto 40px auto;
  height: calc(100vh - 200px) !important;
  // max-height: 700px;
  max-width: 450px;
  .side-bar {
    left: 0px !important;
  }
  @media #{$sm-and-down} {
    position: relative;
    left: -25px;
    // margin-left: -20px;
    // margin-right: -20px;
    // padding-left: 20px;
    // padding-right: 20px;
  }
  iframe {
    width: 100%;
    max-width: 100vw;
    height: 100%;
  }

  .simplybuy-container {
    max-height: calc(100vh - 175px) !important;
  }

  .product-picker {
    padding-top: 15px !important;

    padding-left: 35px;
    padding-right: 0px;
  }
}
