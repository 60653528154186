


// .page {
//   height: 20px;
//   width:100%;
//   background-color:white;
//   position:relative;
//   display:inline-block;
// }

.wrap {
  // margin-top: 25px;
  // perspective: 1000px;
  // perspective-origin: 50% 50%;
}
.cube {
  margin: auto;
  padding-top: 100px;
  position: relative;
  height: 200px;
  width: 100px;
  transform-style: flat;
  @media #{$sm-and-down} {
    padding-top: 75px;
  }
/*   transform: rotateX(70deg) rotateY(0deg)   rotateZ(-45deg); */
}
.change{
  display:none !important;
}
.cube > .circle {
  position: absolute;
// box-sizing: border-box;
/*   padding: 10px; */
  height: 100%;
  width: 100%;
  opacity: 1;
  border-radius: 50%;
  background-color: $theme-orange;
  // background-image: radial-gradient(lighten($theme-orange, 15%),$theme-orange, darken($theme-orange, 15%));
  background: radial-gradient(circle at 30% 30%, lighten($theme-orange,2%), darken($theme-orange, 24%));
  border: solid 1px #20232a;
  z-index: 0;


  &:before {
    content: "";
    position: absolute;
    top: 15%;
    left: -1%;
    width: 80%;
    height: 80%;
    border-radius: 50%;
    background: radial-gradient(circle at 50% 0px, #ffffff, rgba(255, 255, 255, 0) 58%);
    filter: blur(5px);
    z-index: 2;
  }
//   &:after {
//   width: 100%;
//   height: 100%;
//   content: "";
//   position: absolute;
//   top: 5%;
//   left: 10%;
//   border-radius: 50%;
//   background: radial-gradient(circle at 50% 50%,  rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8) 14%, rgba(255, 255, 255, 0) 24%);
//   -webkit-transform: translateX(-27%) translateY(-30%) skewX(-20deg);
//   -moz-transform: translateX(-27%) translateY(-30%) skewX(-20deg);
//   -ms-transform: translateX(-27%) translateY(-30%) skewX(-20deg);
//   -o-transform: translateX(-27%) translateY(-30%) skewX(-20deg);
//   transform: translateX(-27%) translateY(-30%) skewX(-20deg);
//   -webkit-filter: blur(10px);
// }


/*   color: #ffffff; */
  @keyframes fade-in {
    0% {
      opacity: 0;
      // transform: translateY(100px);
        // transform: translateY(100px);
    }
    100% {
      opacity: 1;
    }
      // transform: translateY(0); }
  }

  // @media #{$sm-and-down} {
  //   @keyframes bounce-in-right {
  //     0% {
  //       opacity: 0;
  //       // transform: translateY(100px);
  //         transform: translateY(200px);
  //     }
  //     100% {
  //       opacity: 1;
  //       transform: translateY(0); }
  //   }
  // }

  &.circle1 {
    height: 32px;
    width: 32px;
    animation: fade-in .75s both;
    animation-delay: 2.25s;
  }
  &.circle2 {
    height: 32px;
    width: 32px;
    animation: fade-in .75s both;
    animation-delay: 2.25s;
  }
  &.circle3 {
    height: 32px;
    width: 32px;
    animation: fade-in .75s both;
    animation-delay: 2.25s;
  }
  &.circle4 {
    height: 32px;
    width: 32px;
    animation: fade-in .75s both;
    animation-delay: 2.25s;
  }
   &.circle5 {
    height: 40px;
    width: 40px;
    animation: fade-in .75s both;
    animation-delay: 2.25s;
  }
  &.circle6 {
    height: 40px;
    width: 40px;
    animation: fade-in .75s both;
    animation-delay: 2.25s;
  }
  &.circle7 {
    height: 40px;
    width: 40px;
    animation: fade-in .75s both;
    animation-delay: 2.25s;
  }
  &.circle8 {
    height: 40px;
    width: 40px;
    animation: fade-in .75s both;
    animation-delay: 2.25s;
  }
  &.circle9 {
    height: 40px;
    width: 40px;
    animation: fade-in .75s both;
    animation-delay: 2.25s;
  }
  &.circle10 {
    height: 48px;
    width: 48px;
    animation: fade-in .75s both;
    animation-delay: 2.25s;
  }
  &.circle11 {
    height: 48px;
    width: 48px;
    animation: fade-in .75s both;
    animation-delay: 2.25s;
  }
  &.circle12 {
    height: 48px;
    width: 48px;
    animation: fade-in .75s both;
    animation-delay: 2.25s;
  }
  &.circle13 {
    height: 48px;
    width: 48px;
    animation: fade-in .75s both;
    animation-delay: 2.25s;
  }
  &.circle14 {
    height: 56px;
    width: 56px;
    animation: fade-in .75s both;
    animation-delay: 2.25s;
  }
  &.circle15 {
    height: 56px;
    width: 56px;
    animation: fade-in .75s both;
    animation-delay: 2.25s;
  }
  &.circle16 {
    height: 56px;
    width: 56px;
    animation: fade-in .75s both;
    animation-delay: 2.25s;
  }
  &.circle17 {
    height: 64px;
    width: 64px;
    animation: fade-in .75s both;
    animation-delay: 2.25s;
  }
  &.circle18 {
    height: 64px;
    width: 64px;
    animation: fade-in .75s both;
    animation-delay: 2.25s;
  }
  &.circle19 {
    height: 72px;
    width: 72px;
    animation: fade-in .75s both;
    animation-delay: 2.25s;
  }
}

.circle {
  transition: all 1.25s ease;
}

.circle1 {
  transform: translate(-106px, -70px);
}
.circle2 {
  transform: translate(-16px, -70px);
}
.circle3 {
  transform: translate(84px, -70px);
}
.circle4 {
  transform: translate(174px, -70px);
}
.circle5 {
  transform: translate(-150px, -55px);
}
.circle6 {
  transform: translate(-60px, -55px);
}
.circle7 {
  transform: translate(30px, -55px);
}
.circle8 {
  transform: translate(120px, -55px);
}
.circle9 {
  transform: translate(210px, -55px);
}
.circle10 {
  transform: translate(158px, -32px);
}
.circle11 {
  transform: translate(72px, -32px);
}
.circle12 {
  transform: translate(-20px, -32px);
}
.circle13 {
  transform: translate(-106px, -32px);
}
.circle14 {
  transform: translate(-66px, 0px);
}
.circle15 {
  transform: translate(22px, 0px);
}
.circle16 {
  transform: translate(110px, 00px);
}
.circle17 {
  transform: translate(-22px, 45px);
}
.circle18 {
  transform: translate(58px, 45px);
}
.circle19 {
  transform: translate(14px, 100px);
}



.circle1Combine {
  transform: translate(34px, 20px);
}
.circle2Combine {
  transform: translate(34px, 20px);
}
.circle3Combine {
  transform: translate(34px, 20px);
}
.circle4Combine {
  transform: translate(34px, 20px);
}
.circle5Combine {
  transform: translate(30px, 16px);
}
.circle6Combine {
  transform: translate(30px, 16px);
}
.circle7Combine {
  transform: translate(30px, 16px);
}
.circle8Combine {
  transform: translate(30px, 16px);
}
.circle9Combine {
  transform: translate(30px, 16px);
}
.circle10Combine {
  transform: translate(26px, 12px);
}
.circle11Combine {
  transform: translate(26px, 12px);
}
.circle12Combine {
  transform: translate(26px, 12px);
}
.circle13Combine {
  transform: translate(26px, 12px);
}
.circle14Combine {
  transform: translate(22px, 8px);
}
.circle15Combine {
  transform: translate(22px, 8px);
}
.circle16Combine {
  transform: translate(22px, 8px);
}
.circle17Combine {
  transform: translate(18px, 4px);
}
.circle18Combine {
  transform: translate(18px, 4px);
}
.circle19Combine {
  transform: translate(14px, 0px);
  // background-color: $theme-blue !important;
  // background-image: radial-gradient($theme-blue, darken($theme-blue, 18%)) !important;
}

.circles-tagline {
  color: white;
  text-align: center;
  opacity: 0%;
  transition: all 1.25s ease;
  // width: 300px;
  font-size: 2rem;
  transform: translateY(75px);
  @media #{$sm-and-down} {
    font-size: 1.5rem;
    transform: translateY(50px);
  }

  &.show-tagline {
    opacity: 100%;
    transform: translateY(-25px);
    @media #{$sm-and-down} {
      transform: translateY(-50px);
    }
  }
}
