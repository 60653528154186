@import './utils';
.manage-price-alerts {
  .no-alerts {
    color: white;
  }

  .switch {
    // vertical-align: top;
    // line-height: 0;
  }

  label {
    div {
      div {
        vertical-align: top;
        line-height: 0;
      }
      .react-switch-bg {
      }
    }
  }

  .white {
    svg {
      margin: 0 auto;
      cursor: pointer;
      path {
        stroke: white;
      }
    }
  }

  .alternate-color {
    background-color: darken($theme-black, 10%);
  }

  h1 {
    text-align: center;
    font-size: 2.5rem;
    line-height: 2.5rem;
    padding-bottom: 5px;
    margin-bottom: 35px;
    font-weight: 500;
    color: white;
    padding-top: 70px;
    &.no-margin {
      margin-bottom: 0px;
    }

    @media #{$sm-and-down} {
      margin-bottom: 10px;
      font-size: 1.6rem;
      line-height: 1.6rem;
      padding-top: 20px;
    }
  }

  table {
    border: 1px solid #ccc;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
  }

  table caption {
    font-size: 1.5em;
    margin: .5em 0 .75em;
  }

  table tr {
    background-color: $theme-black;
    color: white;
    // border: 1px solid #ddd;
    padding: .35em;
  }

  table th,
  table td {
    text-transform: capitalize;
    padding: .625em;
    text-align: center;


    .prefix {
      display: inline-block;
      color: black;
      background-color: $light-grey;
      line-height: inherit;
      padding: 0 5px;
      // border-right: 1px solid;
      font-weight: 600;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    .price-to-beat-data {
      @media #{$md-and-up} {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      // max-width: 100px;
      // min-width: 90px;
      input {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        padding-right: 5px;
        text-align: right;
        color: black;
        // min-width: 80px;
        max-width: calc(100% - 24px);
        -webkit-appearance: none;
        -moz-appearance: textfield;
        max-width: 100px;
        min-width: 80px;
      }
      input[type=number]::-webkit-inner-spin-button,
      input[type=number]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          margin: 0;
      }
    }
    // display: flex;
    // align-items: center;
    // justify-content: center;
  }

  table th {
    // font-size: .85em;
    letter-spacing: .1em;
    text-transform: uppercase;
  }

  @media #{$sm-and-down} {

    .white {
      svg {
        margin-right: 0;
        margin-left: auto;
      }
    }
    table {
      border: 0;
    }

    table caption {
      font-size: 1.3em;
    }

    table thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    table tr {
      border-bottom: 3px solid #ddd;
      display: block;
      // margin-bottom: .625em;
    }

    table td {
      // border-bottom: 1px solid #ddd;
      // display: block;
      // // font-size: .8em;
      // text-align: right;

      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    table thead {
      font-weight: 800;
    }

    table td::before {
      // font-weight: 800;
      content: attr(data-label);
      float: left;
      text-transform: uppercase;
    }

    table td:last-child {
      border-bottom: 0;
    }
  }

}

.filter-label {
  margin-bottom: 5px;
  color: white;
  font-weight: 700;
}

.alerts-flex {
  margin-bottom: 50px;
  // .price-to-beat-col {
    // width: 150px;
  // }
}
