$theme-blue: #06bef0;
$theme-grey: #555;
$light-grey: #d4d4d4;
$theme-orange: #f08706;
$theme-black: #20232a;
$theme-md-grey: #bbb;
$theme-red: #f01206;
$theme-green: #04a55d;
$xs-screen-up: 768px !default;
$sm-screen-up: 769px !default;
$md-screen-up: 993px !default;
$lg-screen-up: 1201px !default;

$xs-screen: 767px !default;
$sm-screen: 768px !default;
$md-screen: 992px !default;
$lg-screen: 1200px !default;

$md-and-up: "only screen and (min-width : #{$sm-screen-up})" !default;
$lg-and-up: "only screen and (min-width : #{$md-screen-up})" !default;
$extra-lg-and-up: "only screen and (min-width : #{$lg-screen-up})" !default;
$xs-only: "only screen and (max-width : #{$xs-screen})" !default;
$sm-and-down: "only screen and (max-width : #{$sm-screen})" !default;
$md-and-down: "only screen and (max-width : #{$md-screen})" !default;
$lg-and-down: "only screen and (max-width : #{$lg-screen})" !default;
$md-only: "only screen and (min-width : #{$sm-screen-up}) and (max-width : #{$md-screen})" !default;

$transition-speed: 0.25s;

.lds-roller {
  // display: inline-block;
  // position: relative;
  width: 80px;
  height: 80px;
  margin: 25px auto;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #06bef0;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
