
.snowboard-widget {
  max-width: 440px;
  margin: 0 auto;
  // padding-top: 20px;
  @media #{$sm-and-down} {
    // max-width: 340px;
    width: 100%;
    max-width: 100%;
    // min-width: 100vw;
  }

  .calc-sliders {
    margin: 0 -15px;
    padding: 20px 45px;
    background-color: #ddd;
    border-radius: 10px;

    &.tv {
        background: white;
        padding: 0;
        min-width: 350px;
        max-width: calc(100vw - 50px);
    }

    @media #{$sm-and-down} {
      // max-width: 340px;
      border-radius: 0px;
      // margin: 0 -15px;
      padding: 20px 45px;
    }

    h1 {
      text-align: center;
      font-weight: 700;
      font-size: 1.2rem;
    }

    .noUi-tooltip {
      background-color: transparent;
    }
  }

  .slider-fade {
    animation: fadeIn 1s;
    @-webkit-keyframes fadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
    }
    @keyframes fadeIn {
        from { opacity: 0; }
          to { opacity: 1; }
    }
    .size-adjust-text {
      padding: 15px 25px 0px 25px;
    }
  }

  .slider-container {
    padding-top: 40px;

    &.bottom-sliders {
      padding: 40px 25px 0px 25px;
    }

    .slider-label {
      font-size: .9rem;
      font-weight: 500;
      padding-top: 2px;
    }

    .noUi-target {
      border: none;
      border-radius: 3px;
      // border: 1px solid #ddd;
      box-shadow: 0 3px 6px -3px black !important;
      .noUi-base {
        border-radius: 3px;
        background-color: $theme-blue;
      }
    }


    .noUi-connect {
      background-color: $theme-orange;
    }

    .noUi-touch-area {
      // background-color: $theme-orange;
      // border-radius: 3px;
    }
    .noUi-handle {
      background-color: $theme-orange;
      border: 2px solid black;
      border-radius: 7px;
      box-shadow: inset 0 0 1px black, inset 0 1px 7px black, 0 3px 6px -3px black !important;
      &:before {
        display: none;
      }
      &:after {
        display: none;
      }
    }
    .noUi-tooltip {
      font-size: .9rem;
      font-weight: 700;
      padding: 2px 5px;
      border: none;
    }
  }

}
