.profile-flex {
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row-reverse;
  @media #{$sm-and-down} {
    flex-direction: column;
    justify-content: center;
    // max-width: 280px;
  }

  .snowboard-widget {
    margin: 0;
    .calc-sliders {
      margin: auto !important;

      @media #{$sm-and-down} {
        // widith: calc(vw100 - 70)
        margin: auto calc(100vw * -.075) !important;
      }
    }
  }

  .user-info {

    form {
      color: black;
    }
    padding-left: 40px;
    // width: 275px;
    @media #{$sm-and-down} {
      padding-bottom: 50px;
      width: 100%;
      padding-left: 0px;
    }
    color: white;
    .info-label {
      font-size: 0.8rem;
      color: #c4c4c4;
    }

    .info-value {
      font-size: 1.2rem;
      margin-bottom: 20px;
      // color: red;
    }

    .form-field {
      border-radius: 3px;
      border: 1px solid #ced4da;
      background-color: #fff;
      font-size: 1rem;
      padding: .375rem .75rem;
      margin: 10px 0 0 0;
      width: 100%;
      box-sizing: border-box;
      &:focus {
        outline: none;
      }
    }
  }
}
