// spacement
$spacement-increment: 5px;
$spacement-xs: $spacement-increment;
$spacement-sm: $spacement-xs + $spacement-increment;
$spacement-md: $spacement-sm + $spacement-increment;
$spacement-lg: $spacement-md + $spacement-increment;
$spacement-xl: $spacement-lg + $spacement-increment;
$spacement-xlg: $spacement-xl + $spacement-increment;

// SPACEMENTS
// -----------------------------------------------------------------------------
/* Spacements */
/* spacement top & bottom */
.m-none {
  margin: 0 !important;
}

.m-auto {
  margin: 0 auto !important;
}

.m-xs {
  margin: $spacement-xs !important;
}

.m-sm {
  margin: $spacement-sm !important;
}

.m-md {
  margin: $spacement-md !important;
}

.m-lg {
  margin: $spacement-lg !important;
}

.m-xl {
  margin: $spacement-xl !important;
}

.m-xlg {
  margin: $spacement-xlg !important;
}

/* spacement top  */
.mt-none {
  margin-top: 0 !important;
}

.mt-xs {
  margin-top: $spacement-xs !important;
}

.mt-sm {
  margin-top: $spacement-sm !important;
}

.mt-md {
  margin-top: $spacement-md !important;
}

.mt-lg {
  margin-top: $spacement-lg !important;
}

.mt-xl {
  margin-top: $spacement-xl !important;
}

.mt-xlg {
  margin-top: $spacement-xlg !important;
}

/* spacement bottom  */
.mb-none {
  margin-bottom: 0 !important;
}

.mb-xs {
  margin-bottom: $spacement-xs !important;
}

.mb-sm {
  margin-bottom: $spacement-sm !important;
}

.mb-md {
  margin-bottom: $spacement-md !important;
}

.mb-lg {
  margin-bottom: $spacement-lg !important;
}

.mb-xl {
  margin-bottom: $spacement-xl !important;
}

.mb-xlg {
  margin-bottom: $spacement-xlg !important;
}

/* spacement left  */
.ml-none {
  margin-left: 0 !important;
}

.ml-xs {
  margin-left: $spacement-xs !important;
}

.ml-sm {
  margin-left: $spacement-sm !important;
}

.ml-md {
  margin-left: $spacement-md !important;
}

.ml-lg {
  margin-left: $spacement-lg !important;
}

.ml-xl {
  margin-left: $spacement-xl !important;
}

.ml-xlg {
  margin-left: $spacement-xlg !important;
}

/* spacement left  */
.mr-none {
  margin-right: 0 !important;
}

.mr-xs {
  margin-right: $spacement-xs !important;
}

.mr-sm {
  margin-right: $spacement-sm !important;
}

.mr-md {
  margin-right: $spacement-md !important;
}

.mr-lg {
  margin-right: $spacement-lg !important;
}

.mr-xl {
  margin-right: $spacement-xl !important;
}

.mr-xlg {
  margin-right: $spacement-xlg !important;
}

/* Spacement Padding */
.p-none {
  padding: 0 !important;
}

.p-xs {
  padding: $spacement-xs !important;
}

.p-sm {
  padding: $spacement-sm !important;
}

.p-md {
  padding: $spacement-md !important;
}

.p-lg {
  padding: $spacement-lg !important;
}

.p-xl {
  padding: $spacement-xl !important;
}

.p-xlg {
  padding: $spacement-xlg !important;
}

/* spacement top  */
.pt-none {
  padding-top: 0 !important;
}

.pt-xs {
  padding-top: $spacement-xs !important;
}

.pt-sm {
  padding-top: $spacement-sm !important;
}

.pt-md {
  padding-top: $spacement-md !important;
}

.pt-lg {
  padding-top: $spacement-lg !important;
}

.pt-xl {
  padding-top: $spacement-xl !important;
}

.pt-xlg {
  padding-top: $spacement-xlg !important;
}

/* spacement bottom  */
.pb-none {
  padding-bottom: 0 !important;
}

.pb-xs {
  padding-bottom: $spacement-xs !important;
}

.pb-sm {
  padding-bottom: $spacement-sm !important;
}

.pb-md {
  padding-bottom: $spacement-md !important;
}

.pb-lg {
  padding-bottom: $spacement-lg !important;
}

.pb-xl {
  padding-bottom: $spacement-xl !important;
}

.pb-xlg {
  padding-bottom: $spacement-xlg !important;
}

/* spacement left  */
.pl-none {
  padding-left: 0 !important;
}

.pl-xs {
  padding-left: $spacement-xs !important;
}

.pl-sm {
  padding-left: $spacement-sm !important;
}

.pl-md {
  padding-left: $spacement-md !important;
}

.pl-lg {
  padding-left: $spacement-lg !important;
}

.pl-xl {
  padding-left: $spacement-xl !important;
}

.pl-xlg {
  padding-left: $spacement-xlg !important;
}

/* spacement right  */
.pr-none {
  padding-right: 0 !important;
}

.pr-xs {
  padding-right: $spacement-xs !important;
}

.pr-sm {
  padding-right: $spacement-sm !important;
}

.pr-md {
  padding-right: $spacement-md !important;
}

.pr-lg {
  padding-right: $spacement-lg !important;
}

.pr-xl {
  padding-right: $spacement-xl !important;
}

.pr-xlg {
  padding-right: $spacement-xlg !important;
}

.ta-center {
  text-align: center;
}

.ta-right {
  text-align: right;
}

.fl-left {
  float: left;
}

.fl-right {
  float: right;
}

.b1-top {
  border-top: 1px solid black;
}

.clickable {
  cursor: pointer;
}

.w-50 {
  width: 50%;
}

.w-100 {
  width: 100%;
}

.inline {
  display: inline-block;
}

.b-radius-2 {
  border-radius: 2px;
}

.fw-500 {
  font-weight: 500;
}

.d-flex {
  display: flex;
}

.display-none {
  display: none !important;
}
.hidden-md-up {
  @media #{$md-and-up} {
    display: none !important;
  }
}

.hidden-sm-down {
  @media #{$sm-and-down} {
    display: none !important;
  }
}

.t-white {
  color: white;
}
